import React, { useContext } from "react";
import { fireToast } from "../../common/Toster";
import { FaCopy } from "react-icons/fa";
import { ConversionContext } from "../../Context/context";
import {
  FacebookShareButton,
  WhatsappShareButton,
  TelegramShareButton,
  TwitterShareButton,
} from "react-share";
import FacebookIcon from "../../images/facebookIcon.png";
import WtsupIcon from "../../images/wtsupIcon.png";
import TelegramIcon from "../../images/telegramIcon.png";
import TwitterIcon from "../../images/twitterIcon.png";
import { useActiveAccount } from "thirdweb/react";

function ReceiveTab() {
  // const address = useAddress();
  const activeAccount = useActiveAccount();
  const { userData } = useContext(ConversionContext);
  const qrcode = `https://api.qrserver.com/v1/create-qr-code/?data=${activeAccount?.address}&amp;size=200x200`;

  return (
    <div className="buy">
      <div className="swap-buy py-4 position-relative">
        <div className="text-center">
          <img width={200} height={200} src={qrcode} alt="" />
        </div>
      </div>
      <div className="transfer mt-2">
        <p className="mt-2 mb-4">
          User-Name :{" "}
          <span className="fw-600 text-black">{userData?.userName} </span>
          <i
            className="text-black"
            onClick={() => {
              navigator.clipboard.writeText(userData?.userName);
              fireToast("success", "Copied!");
            }}
          >
            <FaCopy />
          </i>
        </p>
        <p className="mt-2 mb-4">
          Wallet Address :{" "}
          <span className="fw-600 text-black">
            {activeAccount?.address?.toString()?.slice(0, 6)}...
            {activeAccount?.address?.toString()?.slice(36, 42)}{" "}
          </span>
          <i
            className="text-black"
            onClick={() => {
              navigator.clipboard.writeText(activeAccount?.address);
              fireToast("success", "Copied!");
            }}
          >
            <FaCopy />
          </i>
        </p>
      </div>
      <div className="mt-2">
        <div className="row py-4">
          <div className="col text-center">
            <FacebookShareButton
              url={qrcode}
              quote={`Here is my Solos wallet QR code ${qrcode} Address: ${activeAccount?.address} , Name: ${userData?.userName}`}
            //   quote="Please share this post"
            // hashtag="#referral"
            >
              <img height={50} alt="" src={FacebookIcon} />
            </FacebookShareButton>
          </div>
          <div className="col text-center">
            <TwitterShareButton
              //   url="users.solosfi.com"
              url={`Here is my Solos wallet QR code ${qrcode}  Address: ${activeAccount?.address} , Name: ${userData?.userName}`}
              quote="Please share this post"
              hashtag="#code"
            >
              <img height={50} alt="" src={TwitterIcon} />
            </TwitterShareButton>
          </div>
          <div className="col text-center">
            <WhatsappShareButton
              url={`Here is my Solos wallet QR code ${qrcode}  Address: ${activeAccount?.address} , Name: ${userData?.userName}`}
              //   url="users.solosfi.com"
              quote="Please share this post"
              hashtag="#referral"
            >
              <img height={50} alt="" src={WtsupIcon} />
            </WhatsappShareButton>
          </div>
          <div className="col text-center">
            <TelegramShareButton
              url={`Here is my Solos wallet QR code ${qrcode} Address: ${activeAccount?.address} , Name: ${userData?.userName}`}
              quote="Please share this post"
              hashtag="#code"
            >
              <img height={50} alt="" src={TelegramIcon} />
            </TelegramShareButton>
          </div>
        </div>
      </div>
      {/* <button className="btn w-100 mt-3 p-3 connect-wallet-btn">Receive</button> */}
    </div>
  );
}

export default ReceiveTab;

/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import logo from "../images/r-logo.svg";
import "./custom.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { userRegister2 } from "../services/userService";
import { fireToast } from "../common/Toster";
import { AiOutlineEye } from "react-icons/ai";
import { AiOutlineEyeInvisible } from "react-icons/ai";
// import { countries } from 'iso-3166-1-alpha-2';
// import { data as subdivisions } from 'iso-3166-2';
// import getUnicodeFlagIcon from 'country-flag-icons/unicode'
import PhoneInput, { parsePhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";

// const countryOptions = Object.entries(countries).map(([code, name]) => (
//   <option key={code} value={code}>
//     {`${getUnicodeFlagIcon(code)} ${name}`}
//   </option>
// ));
const Register = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const referralCode = params.get('refer-by');
  const [passwordType, setPasswordType] = useState("password");
  let navigate = useNavigate();
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
      password: "",
      referralCode: referralCode,
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid Email Format")
        .required("Email is Required")
        .min(6, "Email must be 6 characters long"),

      password: Yup.string()
        .required("Password is Required")
        .min(8, "Password must be 8 characters long")
        .matches(/[0-9]/, "Password requires a number")
        .matches(/[a-z]/, "Password requires a lowercase letter")
        .matches(/[A-Z]/, "Password requires an uppercase letter")
        .matches(/[^\w]/, "Password requires a symbol"),
    }),
    onSubmit: async (values) => {
      try {
        // changeApiStatus(true, "");
        const loginResponse = await userRegister2(values);
        if (loginResponse.status === 200) {
          localStorage.clear();
          // console.log(loginResponse, "loginResponse");
          fireToast("success", loginResponse.message);
          localStorage.setItem("contact-email", loginResponse?.data?.email);
          localStorage.setItem("contact-info", JSON.stringify(loginResponse));
          navigate("/otp");
        } else {
          // changeApiStatus(false, "");

          throw new Error(loginResponse.error);
        }
      } catch (err) {
        console.log(err, ">>>>>>>>>>>>>>>");
        fireToast("error", err?.response?.data?.error);
        // changeApiStatus(false, "");
      }
    },
  });

  return (
    <div className="auth-bg mh-100 d-flex align-items-center justify-content-center">
      <div className="signup-card">
        <div className="signup-inner">
          <div className="text-center mb-2">
            <Link to="https://solosfi.com/">
              <img className="mx-auto" src={logo} alt="" />
            </Link>
            <h1 className="fs-32 mt-3">Sign Up</h1>
            <p className="f-14">
              Please add your details to begin <br /> your journey
            </p>
          </div>

          <Form
            // className="form-horizontal"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <div className="row">
              <div className="mb-3 col-md-12">
                <Form.Group className="position-relative">
                  <Form.Label className="form-label">Email</Form.Label>
                  <Form.Control
                    className="form-ctl "
                    name="email"
                    placeholder={"Enter Email"}
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.email || ""}
                    invalid={
                      validation.touched.email && validation.errors.email
                        ? "true"
                        : "false"
                    }
                  />
                  {validation.touched.email && validation.errors.email ? (
                    <div className="text-danger error-msg">
                      {validation.errors.email}
                    </div>
                  ) : null}
                </Form.Group>
              </div>

              <div className="mb-3 col-md-12">
                <div className="position-relative">
                  <Form.Group className="position-relative">
                    <Form.Label className="form-label">{"Password"}</Form.Label>
                    <Form.Control
                      name="password"
                      className="form-ctl "
                      type={passwordType}
                      value={validation.values.password || ""}
                      placeholder={"Enter Password"}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      invalid={
                        validation.touched.password &&
                          validation.errors.password
                          ? true
                          : false
                      }
                    />
                    {passwordType === "password" ? (
                      <span>
                        <AiOutlineEye
                          onClick={() => setPasswordType("text")}
                          className="view_password"
                        />{" "}
                      </span>
                    ) : (
                      <span>
                        <AiOutlineEyeInvisible
                          onClick={() => setPasswordType("password")}
                          className="view_password"
                        />{" "}
                      </span>
                    )}
                    {validation.touched.password &&
                      validation.errors.password ? (
                      <div className="text-danger error-msg">
                        {validation.errors.password}
                      </div>
                    ) : null}
                  </Form.Group>
                </div>
              </div>
              <div className="mb-3 col-md-12">
                <Form.Group className="position-relative">
                  <Form.Label className="form-label">
                    Referral Code (Optional)
                  </Form.Label>
                  <Form.Control
                    className="form-ctl "
                    name="referralCode"
                    placeholder={"Enter Referral Code"}
                    type="text"
                    value={validation.values.referralCode || ""}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                  />
                  {validation.touched.referralCode &&
                    validation.errors.referralCode ? (
                    <div className="text-danger error-msg">
                      {validation.errors.referralCode}
                    </div>
                  ) : null}
                </Form.Group>
              </div>
              <div className="mt-3 d-grid">
                <Button
                  className="common-btn mt-2 mb-3 w-100"
                  variant="primary"
                  type="submit"
                >
                  <strong>Sign Up</strong>
                </Button>
              </div>
            </div>

            <p className="f-15 text-center">
              Already have an account?
              <Link to="/login" className="text-theme fw-600 ms-2">
                <u>Sign In</u>
              </Link>
            </p>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Register;

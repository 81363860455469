import React from "react";
import siteLogo from "../../images/site-logo.svg";
import congrats from "../../images/congrats.png";
import "./OtpVerified.css";
import { Link, useNavigate, useLocation } from "react-router-dom";

const OtpVerified = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const loginResponse = location?.state?.data;
  return (
    <>
      <div className="otp-verified auth-bg sign-in mh-100 d-flex align-items-center justify-content-center">
        <div>
          <div className="text-center">
            <Link to="/">
              <img className="mx-auto" src={siteLogo} alt="" />
            </Link>
            <h1 className="fs-32 mt-3">Congratulations</h1>
            <img alt="" src={congrats} />
          </div>
          <div className="auth-card py-lg-3">
            <div className="auth-inner text-center">
              <h4 className="mb-4" style={{ color: "green" }}>
                Email Verified!!
              </h4>
              <p className="mb-4" style={{ color: "green" }}>
                Start exploring your dashboard now!
              </p>
              <button
                className="btn btn-primary"
                onClick={() => {
                  localStorage.setItem(
                    "reg-data",
                    JSON.stringify(loginResponse)
                  );
                  navigate("/dashboard");
                }}
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OtpVerified;

/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { getUserMarketplaceNfts } from "../services/NftServices";
import { Link } from "react-router-dom";
import { FaCircleChevronLeft, FaCopy } from "react-icons/fa6";
import { BiChevronRight } from "react-icons/bi";
import SellNftCard from "../components/Nft/SellNftCard";
import { useNavigate } from "react-router-dom";
import Loader from "../components/loader/loader";
import NoData from "../components/NoDataComp/NoData";
import Pagination from "@mui/material/Pagination";
const debounceTime = 300;
let distinctUntilChanged = null;

const ViewAllSellNfts = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState("");
  const [page, setPage] = useState(1);
  const [userMarketplaceNfts, setUserMarketplaceNfts] = useState([]);

  const [totalData, setTotalData] = useState("");
  const fetchUserMarketplaceNfts = async (
    pageNumber = 1,
    pageSize = 12,
    search = ""
  ) => {
    try {
      setLoading(true);
      const response = await getUserMarketplaceNfts(
        pageNumber,
        pageSize,
        search
      );
      if (response.status === 200) {
        setLoading(false);
        setUserMarketplaceNfts(response.data.items);
        setTotalData(response?.data?.totalItems);
      } else {
        throw new Error(response.error);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching nft data:", error);
    }
  };
  const handleSearch = (val) => {
    const value = val.trim();
    setFilter(value);
    if (distinctUntilChanged) {
      clearTimeout(distinctUntilChanged);
      distinctUntilChanged = null;
    }
    distinctUntilChanged = setTimeout(() => {
      setFilter(value);
      fetchUserMarketplaceNfts(1, 8, value);
      distinctUntilChanged = null;
    }, debounceTime);
  };

  useEffect(() => {
    fetchUserMarketplaceNfts();
  }, [page]);

  return (
    <div className="p-4">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Link
          className="back-btn fw-500 text-black py-4"
          onClick={() => navigate(-1)}
        >
          <i className="pe-2 align-middle">
            <FaCircleChevronLeft />
          </i>
          Back
        </Link>
        <div>
          <input
            type="search"
            placeholder="Search NFT"
            style={{
              borderRadius: "12px",
              border: "none",
              padding: "5px 20px",
              background: "#d3d3d3a1",
            }}
            value={filter}
            onChange={(e) => handleSearch(e.target.value)}
          />
        </div>
      </div>
      {loading ? (
        <Loader />
      ) : (
        <div className="dashboard-centerd">
          {userMarketplaceNfts?.length > 0 ? (
            <div className="row g-0">
              <div className="row mb-4">
                {userMarketplaceNfts?.map((item, i) => {
                  return (
                    <div
                      key={i}
                      className="col-xxl-4 col-xl-4 col-md-6 col-12 mb-4"
                    >
                      <SellNftCard
                        data={item}
                        fetchData={fetchUserMarketplaceNfts}
                        setLoading={setLoading}
                      />
                    </div>
                  );
                })}
              </div>
              {totalData > 12 && (
                <div
                  style={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    // minWidth: "200%",
                  }}
                >
                  <Pagination
                    color="primary"
                    count={Math.ceil(totalData / 12)}
                    page={page}
                    onChange={(e, v) => setPage(v)}
                    // activePage={page}
                  />
                </div>
              )}
            </div>
          ) : (
            <NoData />
          )}
        </div>
      )}
    </div>
  );
};

export default ViewAllSellNfts;

/* eslint-disable no-unused-vars */
import { initializeApp } from "firebase/app";
import { getToken, getMessaging, onMessage } from "firebase/messaging";
import { fireToast } from "../common/Toster";

const firebaseConfig = {
  apiKey: "AIzaSyBmZ3CWS8wRL20QYUDtrqsGzuCl6DLE-T8",
  authDomain: "solos-e8cdb.firebaseapp.com",
  projectId: "solos-e8cdb",
  storageBucket: "solos-e8cdb.appspot.com",
  messagingSenderId: "747585682062",
  appId: "1:747585682062:web:4e52f8e92173c1010027df",
  measurementId: "G-XYVKJLYPX7",
};
const firebaseApp = initializeApp(firebaseConfig);

const messaging = getMessaging(firebaseApp);

console.log(messaging, "messaging>>>>>>");

export const getOrRegisterServiceWorker = () => {
  if ("serviceWorker" in navigator) {
    return window.navigator.serviceWorker
      .getRegistration("/firebase-push-notification-scope")
      .then((serviceWorker) => {
        if (serviceWorker) return serviceWorker;
        return window.navigator.serviceWorker.register(
          "/firebase-messaging-sw.js",
          {
            scope: "/firebase-push-notification-scope",
          }
        );
      });
  }
  throw new Error("The browser doesn`t support service worker.");
};

export const getFirebaseToken = () =>
  getOrRegisterServiceWorker().then((serviceWorkerRegistration) =>
    getToken(messaging, {
      vapidKey:
        "BIDwPwcISo1XTyy3LKq4No52oldZ9TAWsK4LehCmUwXeQy5Da6B5eKld8f29Nip6ATN_6YCwnjA2nIJWcOb8Q2E",
      serviceWorkerRegistration,
    })
  );

export const onForegroundMessage = () =>
  new Promise((resolve) => onMessage(messaging, (payload) => resolve(payload)));

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log("payload", payload);

      const {
        notification: { title, body },
      } = payload;
      console.log(body, "notification");
      // fireToast('info',body)
      resolve(payload);
    });
  });

/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import Loader from "../components/loader/loader";
import NoData from "../components/NoDataComp/NoData";
import BuyFredCard from "../components/Fred/BuyFredCard";
import { useNavigate, Link } from "react-router-dom";
import { FaCircleChevronLeft, FaCopy } from "react-icons/fa6";
import { getFredBuyedByUser } from "../services/NftServices";
import { useActiveAccount } from "thirdweb/react";

const ViewAllBuyFred = () => {
  const navigate = useNavigate();
  const activeAccount=useActiveAccount();
  const [loading, setLoading] = useState(false);
  const [dbFreds, setDbFreds] = useState([]);
  const [nftOwnedByUser, setNftOwnedByUser] = useState([]);
  const [address, setAddress] = useState("");
  const [possestionsFreds, setPossestionsFreds] = useState([]);
  const [apiHit, setApiHit] = useState(false);

  const fetchBuyedFreds = async () => {
    try {
      setLoading(true);
      const FredBuyedResponse = await getFredBuyedByUser(activeAccount?.address);
      if (FredBuyedResponse.status === 200) {
        setDbFreds(FredBuyedResponse?.data);
        setLoading(false);
      } else {
        throw new Error(FredBuyedResponse.error);
      }
    } catch (error) {
      console.error("Error fetching nft data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBuyedFreds();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return loading ? (
    <Loader />
  ) : (
    <div className="p-4">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Link
          className="back-btn fw-500 text-black py-4"
          onClick={() => navigate(-1)}
        >
          <i className="pe-2 align-middle">
            <FaCircleChevronLeft />
          </i>
          Back
        </Link>
      </div>

      <div className="dashboard-centerd">
        {dbFreds?.length > 0 ? (
          <div className="row g-0">
            <div className="row mb-4">
              {dbFreds?.map((item, i) => {
                return (
                  <div
                    key={i}
                    className="col-xxl-3 col-xl-3 col-md-6 col-12 mb-4"
                  >
                    <BuyFredCard
                      data={item}
                      fetchData={fetchBuyedFreds}
                      setLoading={setLoading}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          <NoData />
        )}
      </div>
    </div>
  );
};

export default ViewAllBuyFred;

import React, { useContext } from "react";
import ConvertTab from "../components/SwapTabs/ConvertTab";
import ReceiveTab from "../components/SwapTabs/ReceiveTab";
import BuyTab from "../components/SwapTabs/BuyTab";
import { ConversionContext } from "../Context/context";
import SendTab from "../components/SwapTabs/SendTab";
import SyncLoading from "../components/loader/syncLoader";
const Swap = () => {
  const { activeSwapTab, setActiveSwapTab, swapLoader } = useContext(ConversionContext);

  const tabs = [
    {
      name: 'convert',
      content: <ConvertTab />
    },
    {
      name: 'receive',
      content: <ReceiveTab />
    },
    {
      name: 'send',
      content: <SendTab />
    },
    {
      name: 'fierce',
      content: <BuyTab />
    }
  ];
  return (
    <div className="mt-5">
      <div className="tab_container">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="tab-panel">
                <ul
                  className="nav nav-pills mb-3 tab-buttons"
                  id="pills-tab"
                >
                  {
                    tabs.map((tab) => {
                      return (
                        <li className="nav-item" role="presentation" key={tab?.name}>
                          <button
                            className={`nav-link text-capitalize ${activeSwapTab === tab.name ? 'active' : ''}`}
                            onClick={() => setActiveSwapTab(tab.name)}
                            id={`pills-${tab.name}-tab`}
                            type="button"
                          >
                            {tab.name}
                          </button>
                        </li>
                      )
                    })
                  }
                </ul>
                {
                  swapLoader ?
                    <SyncLoading />
                    :
                    <div className="tab-content" id="pills-tabContent">
                      {
                        tabs.map((tab) => {
                          return (
                            <div
                              className={`tab-pane fade ${activeSwapTab === tab.name && 'show active'}`}
                              id={`pills-${tab.name}`}
                              key={tab.name}
                            >
                              {tab.content}
                            </div>
                          )
                        })
                      }
                    </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Swap;

/* eslint-disable no-unused-vars */
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Conversions from "./Context/context";
import { ThirdwebProvider } from "thirdweb/react";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ThirdwebProvider>
      <Conversions>
        <Router>
          <App />
        </Router>
      </Conversions>
      <ToastContainer />
    </ThirdwebProvider>
  </React.StrictMode>
);

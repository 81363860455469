/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaPen } from "react-icons/fa6";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import CountryFlag from "../components/Countryflag/CountryFlag";
import { Password } from "../auth/Password";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import Buy from "../images/icon7.svg";
import pdf from "../images/pdf.svg";
import Select from "react-select";
import countryList from "react-select-country-list";
import moment from "moment";
import momentTimeZone from "moment-timezone";
import {
  postLeaseRequest,
  getLeaseRequest,
} from "../services/propertyServices";
import { FileUploader } from "react-drag-drop-files";
import { BsPencilSquare } from "@react-icons/all-files/bs/BsPencilSquare";
import { MdDeleteForever } from "@react-icons/all-files/md/MdDeleteForever";
import { fireToast } from "../common/Toster";
import SuccessModal from "../common/successModal";
import Loader from "../components/loader/loader";
import Pagination from "@mui/material/Pagination";
const Settings = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [loader, setLoader] = useState(false);
  const [success, setSuccess] = useState(false);
  const options = useMemo(() => countryList().getData(), []);
  const fileTypes = [
    "Accepted image formats : JPG",
    "PNG",
    "jpeg",
    " (max. 5MB)",
    "pdf",
  ];
  const [image, setImage] = useState(null);

  const handleimage = (file) => {
    // let File = URL.createObjectURL(file)
    setImage(file);
  };

  const editUploadedFile = (e) => {
    let File = URL.createObjectURL(e.target.files[0]);
    setImage(File);
  };
  const [file, setFile] = useState(null);
  const [isFormSubmit, setFormSubmit] = useState(false);
  const [leaseRequests, setLeaseRequests] = useState([]);
  const SignupSchema = Yup.object().shape({
    propertyName: Yup.string().required("Property Name is required"),
    propertyDescription: Yup.string().required("Description is required"),
    addressLine1: Yup.string().required("Address Line 1 is required"),
    addressLine2: Yup.string().required("Address Line 2 is required"),
    state: Yup.string().required("State is required"),
    country: Yup.string().required("Country is required"),
    pincode: Yup.string().required("Pincode is required"),
  });

  useEffect(() => {
    getLeaseRequests();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const getLeaseRequests = async () => {
    const leaseRequests = await getLeaseRequest(page);
    if (leaseRequests.data.data.length) {
      setLeaseRequests(leaseRequests);
      // console.log(leaseRequests.data.data, "leaseRequests");
    }
  };
  function handleFileChange(event) {
    setFile(event.target.files[0]);
  }

  const onFormSubmit = async (data, actions) => {
    setLoader(true);
    // console.log(data, "data");
    setFormSubmit(true);
    if (!image) {
      fireToast("error", "Please upload Documents...");
      setLoader(false);
      return false;
    }
    data["propertyDocument"] = image;
    await postLeaseRequest(data)
      .then((response) => {
        if (response.status == 200) {
          actions.resetForm();
          getLeaseRequests();
          setImage();
          setSuccess(true);
          setLoader(false);
          setFormSubmit(false);
        }
      })
      .catch((error) => {
        fireToast("error", error.response?.data.error);
        console.log(error);
        setLoader(false);
      });
  };
  return loader ? (
    <Loader />
  ) : (
    <>
      <div className="container-fluid">
        <div className="">
          <div className="ps-2">
            <div className="dashboard-centerd">
              <div className="row g-0">
                <div className="col-xl-8 col-xxl-9 pe-4 pt-4 border-end center-height">
                  <div className="row">
                    {/* <div className="col-12 mb-4">
                      <h6 className="fw-500 text-black">Property Request</h6>
                    </div> */}
                    <div className="col-xl-12">
                      <Formik
                        initialValues={{
                          propertyName: "",
                          propertyDescription: "",
                          addressLine1: "",
                          addressLine2: "",
                          state: "",
                          country: "",
                          pincode: "",
                        }}
                        validationSchema={SignupSchema}
                        onSubmit={(values, actions) => {
                          setTimeout(() => {
                            onFormSubmit(values, actions);
                            actions.setSubmitting(false);
                          }, 500);
                        }}
                      >
                        {({ handleSubmit, errors, touched, setFieldValue }) => (
                          <Form
                            onSubmit={handleSubmit}
                            className="row user-form"
                          >
                            <Form.Group
                              className="col-12 mb-4"
                              controlId="formBasicEmail"
                            >
                              <Form.Label>Property Name</Form.Label>
                              <Field
                                type="text"
                                placeholder="Property Name"
                                name="propertyName"
                                className="form-ctl"
                              />
                              {errors.propertyName && touched.propertyName ? (
                                <div className="text-danger">
                                  {errors.propertyName}
                                </div>
                              ) : null}
                            </Form.Group>

                            <Form.Group
                              className="col-12 mb-4  "
                              controlId="formBasicEmail"
                            >
                              <Form.Label>Property Description</Form.Label>
                              <Field
                                className="fw-400 h-auto  pro-desc  form-ctl   "
                                type="text"
                                placeholder="Property Description"
                                as="textarea"
                                rows={6}
                                name="propertyDescription"
                              />
                              {errors.propertyDescription &&
                              touched.propertyDescription ? (
                                <div className="text-danger">
                                  {errors.propertyDescription}
                                </div>
                              ) : null}
                            </Form.Group>
                            <Form.Group
                              className="col-md-6 col-12 mb-4"
                              controlId="formBasicEmail"
                            >
                              <Form.Label>Address Line 1</Form.Label>
                              <Field
                                type="text"
                                placeholder="Address Line 1"
                                name="addressLine1"
                                className="form-ctl "
                              />
                              {errors.addressLine1 && touched.addressLine1 ? (
                                <div className="text-danger">
                                  {errors.addressLine1}
                                </div>
                              ) : null}
                            </Form.Group>

                            <Form.Group
                              className="col-md-6 col-12 mb-4"
                              controlId="formBasicPassword"
                            >
                              <Form.Label>Address Line 2</Form.Label>
                              <Field
                                type="text"
                                placeholder="Address Line 2"
                                name="addressLine2"
                                className="form-ctl "
                              />
                              {errors.addressLine2 && touched.addressLine2 ? (
                                <div className="text-danger">
                                  {errors.addressLine2}
                                </div>
                              ) : null}
                            </Form.Group>
                            <Form.Group
                              className="col-md-6 col-12 mb-4"
                              controlId="formBasicPassword"
                            >
                              <Form.Label>Country</Form.Label>
                              {/* <Field
                                type="text"
                                placeholder="country"
                                name="country"
                                className="form-ctl "
                              /> */}
                              <Select
                                className="fs-12 country-select "
                                placeholder="Select Country"
                                options={options}
                                name="country"
                                // value={formik.values.label}
                                onChange={(value) => {
                                  setFieldValue("country", value.label);
                                }}
                              />
                              {errors.country && touched.country ? (
                                <div className="text-danger">
                                  {errors.country}
                                </div>
                              ) : null}
                            </Form.Group>
                            <Form.Group
                              className="col-md-6 col-12 mb-4"
                              controlId="formBasicEmail"
                            >
                              <Form.Label>State</Form.Label>
                              <Field
                                type="text"
                                placeholder="State"
                                name="state"
                                className="form-ctl "
                              />
                              {errors.state && touched.state ? (
                                <div className="text-danger">
                                  {errors.state}
                                </div>
                              ) : null}
                            </Form.Group>

                            <Form.Group
                              className="col-md-6 col-12 mb-4"
                              controlId="formBasicPassword"
                            >
                              <Form.Label>Pincode</Form.Label>
                              <Field
                                type="number"
                                placeholder="Pincode"
                                name="pincode"
                                className="form-ctl "
                              />
                              {errors.pincode && touched.pincode ? (
                                <div className="text-danger">
                                  {errors.pincode}
                                </div>
                              ) : null}
                            </Form.Group>

                            <div className="col-12 mb-5">
                              <h6 className="fw-600 text-black mb-4">
                                Property Documents
                              </h6>

                              <div className="file btn btn-lg ">
                                {/* Upload file */}

                                <FileUploader
                                  className="fileuploader form-control"
                                  handleChange={handleimage}
                                  name="file"
                                  types={fileTypes}
                                />
                              </div>

                              {!image && isFormSubmit ? (
                                <div className="text-danger">
                                  File is required.
                                </div>
                              ) : (
                                ""
                              )}
                            </div>

                            <div className="col-md-6 col-12 mb-3">
                              <Button
                                className="w-100 custom-button"
                                variant="primary"
                                type="submit"
                              >
                                Request Lease
                              </Button>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </div>
                </div>

                <div className="col-xl-4 col-xxl-3">
                  <div className="p-4">
                    <div className="d-flex align-items-center justify-content-between">
                      <h6 className="fw-600 text-black">Lease paid</h6>
                    </div>

                    <div className="table-responsive transaction-data">
                      <table className="w-100">
                        <tbody>
                          {leaseRequests.data?.data.map((element) => {
                            return (
                              <tr key={element._id}>
                                <td
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    element.status == "APPROVED"
                                      ? navigate("/possession-details", {
                                          state:
                                            element?.agreementDetails
                                              ?.propertyId,
                                        })
                                      : fireToast(
                                          "error",
                                          "Your Request is in Pending state.."
                                        )
                                  }
                                >
                                  <div className="trans-list d-flex align-items-center">
                                    <i>
                                      <img src={Buy} alt="" />
                                    </i>
                                    <div className="ps-2">
                                      <h6 className="mb-1 fw-600">
                                        Request Sent
                                      </h6>
                                      <p className="f-12 status ">
                                        Status{" "}
                                        <span
                                          style={{
                                            color:
                                              element.status == "PENDING"
                                                ? "#f9aa4a"
                                                : element.status == "APPROVED"
                                                ? "green"
                                                : "red",
                                          }}
                                        >
                                          {element.status}
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </td>
                                <td className="text-end">
                                  <p className="f-12">
                                    {element.status == "APPROVED"
                                      ? momentTimeZone
                                          .utc(
                                            element?.agreementDetails?.createdAt
                                          )
                                          .tz("America/Los_Angeles")
                                          .format("lll")
                                      : momentTimeZone
                                          .utc(element?.createdAt)
                                          .tz("America/Los_Angeles")
                                          .format("lll")}
                                  </p>
                                </td>
                              </tr>
                            );
                          })}
                          <div
                            style={{
                              marginTop: "10px",
                              marginBottom: "10px",
                              minWidth: "200%",
                            }}
                          >
                            {leaseRequests?.data?.totalCount > 0 ? (
                              <Pagination
                                color="primary"
                                count={Math.ceil(
                                  leaseRequests?.data?.totalCount / 8
                                )}
                                page={page}
                                onChange={(e, v) => setPage(v)}
                              />
                            ) : (
                              ""
                            )}
                          </div>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {success ? <SuccessModal /> : ""}
    </>
  );
};

export default Settings;

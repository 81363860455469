/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import React, { useContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./SuperFredDetail.css";
import momentTimeZone from "moment-timezone";
import PinConfirm from "../Modals/PinConfirm";
import { ethers } from "ethers";
import SfredAbi from "../../Abis/SfredAbi.json";
import PinConfirm2 from "../Modals/PinConfirm";
import { FaCalendar } from "react-icons/fa";
import { getAllClientUsers } from "../../services/propertyServices";
import WaitingLoader from "../loader/waitingLoader";
import { ConversionContext } from "../../Context/context";
import { FaCircleChevronLeft } from "react-icons/fa6";
import { AiOutlineClose } from "react-icons/ai";
import Button from "react-bootstrap/Button";
import {
  getConversionRate,
} from "../../services/propertyServices";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Loader from "../loader/loader";
import {
  fireToast,
  maintenancemodePopup,
} from "../../common/Toster";
import {
  buySolosSFred,
  buyUserToUserSFred,
} from "../../services/NftServices";
import Modal from "react-bootstrap/Modal";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import configURl from "../../runtime.config";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { eth_getTransactionCount, getContract, getRpcClient, prepareContractCall, sendAndConfirmTransaction, sendTransaction } from "thirdweb";
import { polygon } from "thirdweb/chains";
import { client } from "../../runtime.config";
import { useActiveAccount } from "thirdweb/react";
import { allowance, approve, balanceOf, decimals as thirdwebDecimals, transfer } from "thirdweb/extensions/erc20";
import { ethers5Adapter } from "thirdweb/adapters/ethers5";

const SuperFredDetail = () => {
  const activeAccount = useActiveAccount();
  const contract = getContract({
    address: configURl.redNewAddress,
    chain: polygon,
    client,
  });

  const [openBuy, setOpenBuy] = useState(false);
  const [totalRedAmount, setTotalRedAmount] = useState("");
  const [showPinModal2, setShowPinModal2] = useState(false);
  const navigate = useNavigate();
  const [userData, setUserData] = useState([]);
  const [show, setShow] = useState(false);
  const [showSend, setShowSend] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userFullName, SetUserFullName] = useState("");
  const [loader, setLoader] = useState(false);
  const [waitLoader, setWaitLoader] = useState(false);
  const [formData2, setFormData2] = useState({});
  const [showPinModal, setShowPinModal] = useState(false);
  const [formData, setFormData] = useState({});
  const [search1, SetSearch] = useState("");
  const contextData = useContext(ConversionContext);
  const [adminAddress, setAdminAddress] = useState(0);
  const location = useLocation();
  const isPinActive = contextData?.userData?.isMpinActive;
  const data = location?.state?.data;
  const card = location?.state?.card;
  const conversionRate = contextData?.conversionRate;
  const userDataContext = contextData?.userData;
  const handleClose = () => setShow(false);
  const handleCloseSend = () => {
    setShowSend(false);
    SetUserFullName("");
  };
  const handleClosePinModal = () => {
    setShowPinModal(false);
  };
  const handleBuyClose = () => setOpenBuy(false);
  const Schema = Yup.object().shape({
    quantity: Yup.number()
      .required("Please enter Quantity")
      .notOneOf([0], "Quantity must be greater than 0")
      .min(0, "Quantity must be greater than 0")
      .max(data?.balance, `Only ${data?.balance} SFREDs are available`),
    price: Yup.number()
      .moreThan(0, "Price must be greater than 0")
      .required("Please enter price"),
  });
  const BuySchema = Yup.object().shape({
    quantity: Yup.number()
      .required("Please enter Quantity")
      .min(0, "Quantity must be greater than 0")
      .max(
        data?.quantityForSellInSoloMarketPlace,
        `Only ${data?.quantityForSellInSoloMarketPlace} SFREDs are available`
      ),
  });

  //sell on user-marketplace
  const onSubmit = async (values) => {
    (isPinActive
      ? () => {
        setShow(false);
        setFormData2(values);
        setShowPinModal2(true);
      }
      : () => {
        onSellFunction(values);
      })();
  };
  const onSellFunction = async (values) => {
    try {
      setWaitLoader(true);
      setLoading(true);
      const signer = await ethers5Adapter.signer.toEthers({ client, chain: polygon, account: activeAccount });
      const maticBalance = ethers.utils.formatEther(await signer.getBalance());

      const gasPrice = ethers.utils.parseUnits("500", "gwei");
      // const estimatedGasPrice = ethers.utils.formatEther(gasPrice);
      if (+maticBalance < configURl.minMaticLimit) {
        fireToast("error", "Insufficient matic funds for gas price !");
        setWaitLoader(false);
        setLoading(false);
        return;
      }

      const sfredContract = getContract({
        address: data?.contractAddress,
        abi: SfredAbi,
        chain: polygon,
        client,
      });

      console.log(sfredContract, "sfredcon");
      const newAmount = ethers.utils.parseUnits(
        values?.quantity?.toString(),
        await thirdwebDecimals({
          contract: sfredContract
        })
      );

      const transaction = prepareContractCall({
        contract: sfredContract,
        method: "function listSFred(uint256 _SfredPrice, uint256 _SfredCount)",
        params: [values?.price?.toString(), newAmount],
      });
      const receipt = await sendAndConfirmTransaction({
        account: activeAccount,
        transaction: transaction
      });
      console.log(receipt, "listTvt Trnx..");
      if (receipt?.status === "success") {
        fireToast(
          "success",
          "SFRED listed successfully but it will take some time."
        );
        setWaitLoader(false);
        navigate("/accounts");
      }
    } catch (error) {
      console.error("Error fetching SFRED data:", error);
      setWaitLoader(false);
      setLoading(false);
      fireToast("error", "Something went wrong please try again later !");
    }
  };

  const onBuy = (values) => {
    (isPinActive
      ? () => {
        setOpenBuy(false);
        setFormData(values);
        setShowPinModal(true);
      }
      : () => {
        onBuyFunction(values);
      })();
  };
  const handleClosePinModal2 = () => {
    setShowPinModal2(false);
  };
  const onBuyFunction = async (values) => {
    try {
      setWaitLoader(true);
      setLoading(true);
      const gasPrice = ethers.utils.parseUnits("500", "gwei");
      const signer = await ethers5Adapter.signer.toEthers({ client, chain: polygon, account: activeAccount });
      const maticBalance = ethers.utils.formatEther(await signer.getBalance());
      // const estimatedGasPrice = ethers.utils.formatEther(gasPrice);
      if (+maticBalance < configURl.minMaticLimit) {
        fireToast("error", "Insufficient matic funds for gas price !");
        setWaitLoader(false);
        setLoading(false);
        return;
      }
      console.log(data?.contractAddress);
      let amount = `${values?.quantity * data?.tradePrice}`;
      // let balance = await contract.erc20.balanceOf(myAddress);
      const balance = ethers.utils.formatUnits(
        await balanceOf({
          contract: contract,
          address: activeAccount?.address
        }),
        await thirdwebDecimals({
          contract: contract
        })
      );

      console.log(balance, "ball");

      if (+balance < +amount) {
        fireToast("error", "Transfer amount exceeds balance !");
        setWaitLoader(false);
        setLoading(false);
        return;
      }
      const redContract = getContract({
        address: configURl?.redNewAddress,
        abi: contract.abi,
        chain: polygon,
        client
      })
      const redDecimals = await thirdwebDecimals({
        contract: redContract
      })
      // let nonce = await signer.getTransactionCount();
      const rpcRequest = getRpcClient({ client, chain: polygon });
      let nonce = await eth_getTransactionCount(rpcRequest, {
        address: activeAccount?.address,
      });

      const approvedAmount = await allowance({
        contract: redContract,
        owner: activeAccount?.address,
        spender: data?.contractAddress
      })

      // const approvedAmount = await redContract.allowance(
      //   myAddress,
      //   data?.contractAddress
      // );

      console.log(approvedAmount, "o098765");
      let formattedApprovedAmount = ethers.utils.formatUnits(
        approvedAmount,
        redDecimals
      );

      console.log(formattedApprovedAmount, "formattedApprovedAmount");
      console.log(nonce, "nonce");
      if (+formattedApprovedAmount < +amount) {
        console.log("ask for approve");
        nonce++;
        console.log(nonce, "nonce");
        const approveTrnx = approve({
          contract: redContract,
          spender: data?.contractAddress,
          amount: "1000000000000000000000000"
        })
        await sendTransaction({ transaction: approveTrnx, account: activeAccount });
        // const approveTrnx = await redContract.approve(
        //   data?.contractAddress,
        //   "1000000000000000000000000",
        //   {
        //     maxFeePerGas: gasPrice,
        //     maxPriorityFeePerGas: gasPrice,
        //     // nonce: nonce,
        //     gasLimit: 600000,
        //   }
        // );
        console.log(approveTrnx, "approving...");
        // const approved = await approveTrnx.wait();
        // console.log(approved, "approval done");
      }
      nonce++;
      console.log(nonce, "nonce");

      // const sfredContract = new ethers.Contract(
      //   data?.contractAddress,
      //   SfredAbi,
      //   signer
      // );

      const sfredContract = getContract({
        address: data?.contractAddress,
        abi: SfredAbi,
        chain: polygon,
        client
      })

      const decimals = await thirdwebDecimals({
        contract: sfredContract
      })
      // const bigIntBalance = await sfredContract.balanceOf(myAddress);
      // const sfredBalance = ethers.utils.formatUnits(bigIntBalance, decimals);
      const sfredBalance = ethers.utils.formatUnits(
        await balanceOf({
          contract: sfredContract,
          address: activeAccount?.address
        }),
        await thirdwebDecimals({
          contract: sfredContract
        })
      );
      const total = +sfredBalance + +values?.quantity;
      if (total > +data?.SfredHoldLimit) {
        fireToast("error", "Transfer amount exceeds max limit per user !");
        setWaitLoader(false);
        setLoading(false);
        return;
      }
      const sfredNewAmount = ethers.utils.parseUnits(
        values?.quantity?.toString(),
        decimals
      );

      const transaction = prepareContractCall({
        contract: sfredContract,
        method: "function mint(uint256 amount)",
        params: [sfredNewAmount],
      });
      const mintTrnx = await sendAndConfirmTransaction({
        account: activeAccount,
        transaction: transaction
      });
      console.log(mintTrnx, "mintTrnx");
      // const mintTrnx = await sfredContract.mint(sfredNewAmount, {
      //   maxFeePerGas: gasPrice,
      //   maxPriorityFeePerGas: gasPrice,
      //   // nonce,
      //   gasLimit: 600000,
      // });
      if (mintTrnx?.status === 'success') {
        await buySolosSFred({
          contractAddress: data?.contractAddress,
          hashId: mintTrnx?.transactionHash,
          walletAddress: activeAccount?.address,
          quantity: values?.quantity,
          sfredId: data?._id,
          redToken: amount,
        })
          .then(async (res) => {
            setWaitLoader(false);
            setLoading(false);
            fireToast(
              "success",
              "SFRED buy successfully. It will take some time. Please Wait"
            );
            navigate("/marketplace");
          })
          .catch((errApi) => {
            setWaitLoader(false);
            setLoading(false);
            fireToast("error", errApi);
          });
      }
      // const tx = await contract.erc20.transfer(adminAddress, amount);
      // const response = tx.receipt;
      // console.log(response, "response");
      // if (response.status) {
      // }
    } catch (error) {
      setWaitLoader(false);
      setLoading(false);
      console.log(error);
      fireToast("error", "Something went wrong please try again later !");
    }
  };

  const buy = async () => {
    const exChangeRate = await getConversionRate();
    const { adminSFredWallet } =
      exChangeRate.data;
    setAdminAddress(adminSFredWallet);
    setOpenBuy(true);
  };

  const onSell = async () => {
    const exChangeRate = await getConversionRate();
    const { receivewalletaddress } = exChangeRate.data;
    setAdminAddress(receivewalletaddress);
    setShow(true);
  };

  const onSend = async () => {
    setShowSend(true);
  };

  const SignupSchema = Yup.object().shape({
    userName: Yup.string().required("User name is required"),
    quantity: Yup.number()
      .required("Quantity is required")
      .max(data?.balance, `Only ${data?.balance} SFREDs are available`),
    walletAddress: Yup.string()
      .min("42")
      .max("42")
      .required("Address is required"),
  });
  const SendOutsideSchema = SignupSchema.omit('userName');

  const onFormSubmit = async (values, actions) => {
    try {
      setLoader(true);
      const gasPrice = ethers.utils.parseUnits("500", "gwei");
      const signer = await ethers5Adapter.signer.toEthers({ client, chain: polygon, account: activeAccount });
      const maticBalance = ethers.utils.formatEther(await signer.getBalance());
      // const estimatedGasPrice = ethers.utils.formatEther(gasPrice);
      if (+maticBalance < configURl.minMaticLimit) {
        fireToast("error", "Insufficient matic funds for gas price !");
        setLoader(false);
        return;
      }
      // const sfredContract = new ethers.Contract(
      //   data?.contractAddress,
      //   SfredAbi,
      //   signer
      // );
      // const decimals = await sfredContract.decimals();

      const sfredContract = getContract({
        address: data?.contractAddress,
        abi: SfredAbi,
        chain: polygon,
        client
      })

      const decimals = await thirdwebDecimals({
        contract: sfredContract
      })

      const newAmount = ethers.utils.parseUnits(
        values?.quantity?.toString(),
        decimals
      );

      const transaction = transfer({
        contract: sfredContract,
        to: values?.walletAddress,
        amount: values?.quantity
      })
      const response = await sendTransaction({ transaction, account: activeAccount });
      // const response = await sfredContract
      //   .transfer(values?.walletAddress, newAmount, {
      //     maxFeePerGas: gasPrice,
      //     maxPriorityFeePerGas: gasPrice,
      //     gasLimit: 600000
      //   })
      console.log(response, "transfer trnx..");
      if (response) {
        await buyUserToUserSFred({
          contractAddress: data?.contractAddress,
          sfredTransactionHash: response?.transactionHash,
          walletAddressFrom: activeAccount?.address,
          walletAddressTo: values?.walletAddress,
          amount: "0",
          quantity: values?.quantity?.toString(),
          sfredId: data?._id,
        })
          .then(async (response) => {
            console.log(response, "api response..");
            fireToast(
              "success",
              "Sent successfully! It will take some time. Please wait!"
            );
            navigate("/accounts");
            setShowSend(false);
            setLoader(false);
          });
      }
    } catch (error) {
      console.error("Error fetching sfred data:", error);
      setLoader(false);
      fireToast("error", error);
    }
  };
  const fetchUserData = async (
    pageNumber = 1,
    pageSize = 5,
    search = search1
  ) => {
    try {
      const list = await getAllClientUsers(pageNumber, pageSize, search);
      if (list.status === 200) {
        setUserData(list?.data?.items);
      } else {
        throw new Error(list.error);
      }
    } catch (err) { }
  };

  useEffect(() => {
    fetchUserData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search1]);
  const handleSearch = (value) => {
    SetSearch(value);
    fetchUserData();
  };

  return waitLoader ? (
    <WaitingLoader />
  ) : loading ? (
    <Loader />
  ) : (
    <div className="tvtDetail ps-4">
      <Link
        className="back-btn fw-500 text-black pt-4 ms-0"
        onClick={() => navigate(-1)}
      >
        <i className="pe-2 align-middle">
          <FaCircleChevronLeft />
        </i>
        Back
      </Link>
      <div className="dashboard-centerd">
        <div className="row g-0">
          <div className="col-xl-12 col-xxl-12 pe-4 pt-4 border-end center-height pb-5">
            <div className="row mb-5">
              <div className="col-lg-6 sideImage mb-lg-0 mb-4">
                <figure
                  className="mb-0 imageFigure"
                  style={{
                    background: `url(${data?.image})`,
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                  }}
                >
                  {/* <img src={data?.image} /> */}
                </figure>
              </div>
              <div className="col-lg-6 sideText">
                <div className="mainText mb-3">
                  <h4 style={{ color: "#000" }}>{data?.series}</h4>
                  <h6 className="fw-bold" style={{ color: "green" }}>
                    {data?.tradePrice} RED{" "}
                    {`($${(data?.tradePrice * conversionRate)
                      .toString()
                      .match(/^-?\d+(?:.\d{0,2})?/)?.[0]
                      })`}
                  </h6>
                </div>
                {/* { (
                  <div className="p-2" style={{ border: "1px solid" }}>
                    <table className="w-100">
                      <tr>
                        <th>My Holding</th>
                        <th>Value in RED</th>
                        <th>Value in USDC</th>
                      </tr>
                      <tr>
                        <td>{data?.myHolding}</td>
                        <td>{data?.myHolding * data?.tradePrice}</td>
                        <td>
                          {
                            (
                              data?.myHolding *
                              data?.tradePrice *
                              conversionRate
                            )
                              .toString()
                              .match(/^-?\d+(?:.\d{0,2})?/)?.[0]
                          }
                        </td>
                      </tr>
                    </table>
                  </div>
                )} */}
                <div className="mb-2">
                  <span style={{ fontWeight: "600" }}>Name : </span>
                  <span>{data?.name}</span>
                </div>
                <div className="mb-2">
                  <span style={{ fontWeight: "600" }}>Initial Price : </span>
                  <span>{data?.initialPrice} RED</span>
                </div>
                <div className="mb-2">
                  <span style={{ fontWeight: "600" }}>Reward Amount : </span>
                  <span>
                    {
                      data?.type != "none" ?
                        `${data?.paymentAmount} SR on ${data?.paymentDate} every ${data?.type}` : `N.A.`
                    }
                  </span>
                </div>
                <div className="mb-2">
                  <span style={{ fontWeight: "600" }}>Max User Limit : </span>
                  <span>{data?.SfredHoldLimit} </span>
                </div>
                <div className="mb-2">
                  <span style={{ fontWeight: "600" }}>My Holding : </span>
                  <span>{data?.myHolding} </span>
                </div>
                <div className="mb-3">
                  <span style={{ fontWeight: "600" }}>Issued Date : </span>
                  <span>
                    {momentTimeZone
                      .utc(data?.issuedDate)
                      .tz("America/Los_Angeles")
                      .format("DD-MMM-YYYY")}
                  </span>
                </div>
                <div className="descText mt-2">
                  <h6 style={{ color: "#000" }}>Description :</h6>
                  <p>{data?.description}</p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 collectionDetails">
                <div className="p-3 mintNowText">
                  <h6 style={{ color: "#000" }} className="mb-3">
                    {card == "sell"
                      ? "Sell your Super FRED now on user-marketplace"
                      : "Buy now to get your Super FRED"}
                  </h6>
                  <h6 style={{ color: "#000" }} className="mb-3">
                    {data?.tradePrice} RED / {data?.symbol}
                  </h6>
                  <div className="row mb-3">
                    <div className="col-6 mb-2">
                      <FaCalendar /> Maturity Date{" "}
                      <span className="fw-bold" style={{ color: "#000" }}>
                        {momentTimeZone
                          .utc(data?.maturityDate)
                          .tz("America/Los_Angeles")
                          .format("DD-MMM-YYYY")}
                      </span>{" "}
                    </div>
                  </div>
                  {card == "sell" ? (
                    <div className="mintButton">
                      <button className="btn" onClick={() => onSell()}>
                        Sell on User-Marketplace
                      </button>
                      <button className="btn mx-2" onClick={() => onSend()}>
                        Send
                      </button>
                    </div>
                  ) : (
                    <div className="mintButton">
                      <button
                        className="btn"
                        onClick={() => {
                          if (userDataContext?.maintenanceMode) {
                            maintenancemodePopup();
                          } else {
                            buy(data);
                          }
                        }}
                      >
                        Buy Now
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={handleClose}
        animation={false}
      >
        <Modal.Header>
          <h5 className="" style={{ margin: "auto" }}>
            {" "}
            Sell Super FRED on user marketplace
          </h5>
          <span
            style={{ cursor: "pointer" }}
            className="d-flex justify-content-end mb-2"
            onClick={handleClose}
          >
            <AiOutlineClose />
          </span>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Formik
              initialValues={{
                price: data?.tradePrice,
                quantity: "",
              }}
              validationSchema={Schema}
              onSubmit={onSubmit}
              enableReinitialize
            >
              {({ errors, touched, values, setFieldValue, setTouched }) => (
                <Form className="sellNFT-form">
                  <div className="row align-items-start justify-content-center">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-12 mb-3">
                          <label htmlFor="propertyName">Quantity:</label>
                          <Field
                            type="number"
                            name="quantity"
                            id="quantity"
                            placeholder="Enter Quantity"
                            className="form-control"
                          />
                          {errors?.quantity && touched?.quantity ? (
                            <div className="input-error">{errors.quantity}</div>
                          ) : null}
                        </div>
                        <div className="col-md-12 mb-3">
                          <label htmlFor="propertyName">
                            Price (RED) / SFRED:
                          </label>
                          <Field
                            type="number"
                            name="price"
                            id="price"
                            placeholder="Enter Price"
                            className="form-control"
                          />
                          {errors.price && touched.price ? (
                            <div className="input-error">{errors.price}</div>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 col-12">
                      <Button
                        className="w-100 common-btn1"
                        variant="primary"
                        type="submit"
                      >
                        Sell
                      </Button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={openBuy}
        onHide={handleBuyClose}
        animation={false}
      >
        <Modal.Header>
          <h5 className="" style={{ margin: "auto" }}>
            {" "}
            Buy Super FRED
          </h5>
          <span
            style={{ cursor: "pointer" }}
            className=" d-flex justify-content-end mb-2"
            onClick={handleBuyClose}
          >
            <AiOutlineClose />
          </span>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Formik
              initialValues={{
                quantity: "",
              }}
              validationSchema={BuySchema}
              onSubmit={onBuy}
              enableReinitialize
            >
              {({ errors, touched, values, setFieldValue, setTouched }) => (
                <Form className="sellNFT-form">
                  <div className="row align-items-start justify-content-center">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-12 mb-3">
                          <label className="mb-2" htmlFor="propertyName">
                            Quantity:
                          </label>
                          <Field
                            name="quantity"
                            id="quantity"
                            placeholder="Enter Quantity"
                            className="form-control"
                            onChange={(e) => {
                              setFieldValue("quantity", e.target.value);
                              setTotalRedAmount(
                                e.target.value * data?.tradePrice
                              );
                            }}
                          />
                          {errors.quantity && touched.quantity ? (
                            <div className="input-error">{errors.quantity}</div>
                          ) : null}
                        </div>
                        <div className="col-md-12 mb-3">
                          <label className="mb-2" htmlFor="propertyName">
                            Price (In RED):
                          </label>
                          <Field
                            value={totalRedAmount}
                            name="priceinred"
                            id="priceinred"
                            placeholder="Enter Price in RED"
                            className="form-control"
                            disabled
                          />
                          {errors.priceinred && touched.priceinred ? (
                            <div className="input-error">
                              {errors.priceinred}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 col-12">
                      <Button
                        className="w-100 common-btn1"
                        variant="primary"
                        type="submit"
                      >
                        Buy
                      </Button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showSend}
        onHide={handleCloseSend}
        animation={false}
      >
        <Modal.Header>
          <h5 className="" style={{ margin: "auto" }}>
            {" "}
            Send Super FRED
          </h5>
          <span
            style={{ cursor: "pointer" }}
            className="d-flex justify-content-end mb-2"
            onClick={handleCloseSend}
          >
            <AiOutlineClose />
          </span>
        </Modal.Header>
        <Modal.Body>
          <Tabs
            defaultActiveKey="platform"
            id="sendred_tabs"
            className="marketplace-tabs border-0 mb-4"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Tab eventKey="platform" title="Solos Platform">
              <div>
                <Formik
                  initialValues={{
                    userName: "",
                    walletAddress: "",
                    quantity: "",
                  }}
                  validationSchema={SignupSchema}
                  onSubmit={(values, actions) => {
                    setTimeout(() => {
                      onFormSubmit(values, actions);
                      actions.setSubmitting(false);
                    }, 500);
                  }}
                >
                  {({ handleSubmit, errors, touched, setFieldValue }) => (
                    <Form onSubmit={handleSubmit} className="row user-form">
                      <div className="col-md-12 mb-3">
                        <label>Enter User Name</label>
                        <Field
                          onChange={(e) => {
                            setFieldValue("userName", e.target.value);
                            handleSearch(e.target.value);
                          }}
                          autoComplete="off"
                          type="text"
                          placeholder="Enter User Name"
                          name="userName"
                          className="form-ctl"
                        />
                        <h6 className="text-end mb-0 mt-1">{userFullName}</h6>
                        {search1 == "" ? (
                          ""
                        ) : (
                          <div className="customSearch">
                            {userData.length == 0 ? (
                              <div> No Data Found. </div>
                            ) : (
                              userData?.map((item, i) => (
                                <div
                                  onClick={() => {
                                    setFieldValue(
                                      "walletAddress",
                                      item?.walletAddress
                                    );
                                    setFieldValue("userName", item?.userName);
                                    SetUserFullName(
                                      item?.name + " " + item?.lastname
                                    );
                                    SetSearch("");
                                  }}
                                  className="items"
                                  key={i}
                                >
                                  <div
                                    style={{
                                      padding: "5px 0px",
                                      borderBottom: "1px solid #2c29294d",
                                    }}
                                  >
                                    {item?.userName} {" - "}
                                    {item?.name + " " + item?.lastname}
                                  </div>
                                </div>
                              ))
                            )}
                          </div>
                        )}
                        {errors.userName && touched.userName ? (
                          <div className="text-danger">{errors.userName}</div>
                        ) : null}
                      </div>
                      <div className="col-md-12 mb-3">
                        <label>Enter Wallet Address</label>
                        <Field
                          type="text"
                          placeholder="Enter Wallet Address"
                          name="walletAddress"
                          className="form-ctl"
                        />
                        {errors.walletAddress && touched.walletAddress ? (
                          <div className="text-danger">{errors.walletAddress}</div>
                        ) : null}
                      </div>

                      <div className="col-md-12 mb-3">
                        <label>Enter Quantity</label>
                        <Field
                          type="text"
                          placeholder="Enter quantity"
                          name="quantity"
                          className="form-ctl"
                        />
                        {errors.quantity && touched.quantity ? (
                          <div className="text-danger">{errors.quantity}</div>
                        ) : null}
                      </div>

                      <div className="col-12">
                        {loader ? (
                          <Button
                            // disabled={loader}
                            className="w-100 common-btn"
                            variant="primary"
                          >
                            <span className="typeWriter">
                              processing<span> . . . . .</span>
                            </span>
                          </Button>
                        ) : (
                          <Button
                            // disabled={loader}
                            className="w-100 common-btn"
                            variant="primary"
                            type="submit"
                          >
                            Send
                          </Button>
                        )}
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </Tab>
            <Tab eventKey="outsideplatform" title="Outside Solos">
              <div>
                <Formik
                  initialValues={{
                    walletAddress: "",
                    quantity: "",
                  }}
                  validationSchema={SendOutsideSchema}
                  onSubmit={(values, actions) => {
                    setTimeout(() => {
                      onFormSubmit(values, actions);
                      actions.setSubmitting(false);
                    }, 500);
                  }}
                >
                  {({ handleSubmit, errors, touched }) => (
                    <Form onSubmit={handleSubmit} className="row user-form">
                      <div className="col-md-12 mb-3">
                        <label>Enter Wallet Address</label>
                        <Field
                          type="text"
                          placeholder="Enter Wallet Address"
                          name="walletAddress"
                          className="form-ctl"
                        />
                        {errors.walletAddress && touched.walletAddress ? (
                          <div className="text-danger">{errors.walletAddress}</div>
                        ) : null}
                      </div>
                      <div className="col-md-12 mb-3">
                        <label>Enter Quantity</label>
                        <Field
                          type="text"
                          placeholder="Enter quantity"
                          name="quantity"
                          className="form-ctl"
                        />
                        {errors.quantity && touched.quantity ? (
                          <div className="text-danger">{errors.quantity}</div>
                        ) : null}
                      </div>

                      <div className="col-12">
                        {loader ? (
                          <Button
                            // disabled={loader}
                            className="w-100 common-btn"
                            variant="primary"
                          >
                            <span className="typeWriter">
                              processing<span> . . . . .</span>
                            </span>
                          </Button>
                        ) : (
                          <Button
                            // disabled={loader}
                            className="w-100 common-btn"
                            variant="primary"
                            type="submit"
                          >
                            Send
                          </Button>
                        )}
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </Tab>
          </Tabs>

        </Modal.Body>
      </Modal>
      <PinConfirm
        setShow={setOpenBuy}
        formData={formData}
        showPinModal={showPinModal}
        handleSubmit={onBuyFunction}
        handleClosePinModal={handleClosePinModal}
      />
      <PinConfirm2
        setShow={setShow}
        formData={formData2}
        showPinModal={showPinModal2}
        handleSubmit={onSellFunction}
        handleClosePinModal={handleClosePinModal2}
      />
    </div>
  );
};

export default SuperFredDetail;

/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaPen, FaCopy } from "react-icons/fa6";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import CountryFlag from "../components/Countryflag/CountryFlag";
import { useFormik } from "formik";
import Mpin from "./Mpin";
import * as Yup from "yup";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import Dummy from "../images/dummy.png";
import {
  getMyInfo,
  UpdateProfile,
  changePassword,
} from "../services/propertyServices";
import { BsPencilSquare } from "@react-icons/all-files/bs/BsPencilSquare";
import { MdDeleteForever } from "@react-icons/all-files/md/MdDeleteForever";
import { fireToast } from "../common/Toster";
// import { countries } from 'iso-3166-1-alpha-2';
// import { data as subdivisions } from 'iso-3166-2';
// import getUnicodeFlagIcon from 'country-flag-icons/unicode'
import PhoneInput, { parsePhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import Loader from "../components/loader/loader";

// const countryOptions = Object.entries(countries).map(([code, name]) => (
//   <option key={code} value={code}>
//     {`${getUnicodeFlagIcon(code)} ${name}`}
//   </option>
// ));

const Settings = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const [newPasswordType, setnewPasswordType] = useState("password");
  const [tinPass, setTinPass] = useState("password");
  const [image, setImage] = useState(null);
  const [image1, setImage1] = useState(null);
  const [isMpin, setIsMpin] = useState(false);
  const [isPinActive, setIsPinActive] = useState(false);

  const editUploadedFile = (e) => {
    setImage1(e.target.files[0]);
    let File = URL.createObjectURL(e.target.files[0]);
    setImage(File);
  };

  const handleCopy = (value) => {
    navigator.clipboard.writeText(value);
    fireToast("success", "Copied!");
  };
  const [myRef, setMyRef] = useState("");
  const [refBy, setRefBy] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [data, setData] = useState({});
  const getdata = async () => {
    setLoader(true);
    await getMyInfo()
      .then((res) => {
        setIsPinActive(res?.data?.isMpinActive);
        setIsMpin(res?.data?.mpin ? true : false);
        setEmail(res?.data?.email);
        setMyRef(res?.data?.myReferralCode);
        setRefBy(res?.data?.referralUserName);
        setImage(res?.data?.profileImage);
        setUsername(res?.data?.userName);
        setData(res?.data);
        formik.setFieldValue("name", res?.data?.name);
        formik.setFieldValue("contact", res?.data?.contact);
        formik.setFieldValue("walletAddress", res?.data?.walletAddress);
        formik.setFieldValue("lastname", res?.data?.lastname);
        formik.setFieldValue("userName", res?.data?.userName);
        formik.setFieldValue(
          "birth_date",
          res?.data?.birth_date?.split("T")?.[0]
        );
        formik.setFieldValue("countryShortCode", res?.data?.countryShortCode);
        formik.setFieldValue("city", res?.data?.city);
        formik.setFieldValue("countryCode", res?.data?.countryCode);
        formik.setFieldValue("postal_code", res?.data?.postal_code);
        formik.setFieldValue("state", res?.data?.state);
        formik.setFieldValue("street_line_1", res?.data?.street_line_1);
        formik.setFieldValue("street_line_2", res?.data?.street_line_2);
        formik.setFieldValue(
          "tax_identification_number",
          res?.data?.tax_identification_number
        );
      })
      .catch((err) => {
        console.log(err, "err");
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      contact: "",
      walletAddress: "",
      lastname: "",
      birth_date: "",
      countryShortCode: "",
      city: "",
      postal_code: "",
      state: "",
      street_line_1: "",
      street_line_2: "",
      countryCode: "",
      userName: "",
      tax_identification_number: "",
    },
    onSubmit: (data) => {
      setLoader(true);
      // console.log(data, "values");
      data["profileImage"] = image1;
      if (!data?.street_line_2) {
        data.street_line_2 = "NaN";
      }
      UpdateProfile(data)
        .then((response) => {
          if (response.status === 200) {
            setLoader(false);
            getdata();
            fireToast("success", "Successfully updated!");
          }
        })
        .catch((error) => {
          fireToast("error", error.response?.data.error);
          console.log(error);
          setLoader(false);
        });
    },
  });

  useEffect(() => {
    getdata();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const [states, setStates] = useState([]);

  // useEffect(() => {
  //   console.log(formik.values.countryShortCode);
  //   if (formik.values.countryShortCode) {
  // const { sub: countryStates } = subdivisions[formik.values.countryShortCode] || [];
  // console.log(countryStates, "states");
  // setStates(countryStates);
  // formik.setFieldValue("state", "");
  // }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [formik.values.countryShortCode])
  // const stateOptions = Object.entries(states).map(([code, { name }]) => (
  //   <option key={code} value={code}>
  //     {name}
  //   </option>
  // ));

  const passwordFormik = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
    },
    validationSchema: Yup.object({
      oldPassword: Yup.string().required("This Field is Required"),
      newPassword: Yup.string()
        .required("This Field is Required")
        .min(8, "Password must be 8 characters long")
        .matches(/[0-9]/, "Password requires a number")
        .matches(/[a-z]/, "Password requires a lowercase letter")
        .matches(/[A-Z]/, "Password requires an uppercase letter")
        .matches(/[^\w]/, "Password requires a symbol"),
    }),
    onSubmit: (data, { resetForm }) => {
      // console.log(data, "values");
      changePassword(data)
        .then((response) => {
          if (response.status === 200) {
            fireToast("success", "Successfully updated!");
            resetForm();
            setLoader(false);
            setTimeout(() => {
              localStorage.clear();
              localStorage.removeItem("user-info");
              localStorage.removeItem("contact-info");
              localStorage.removeItem("reg-data");
              localStorage.removeItem("isKycCompleted");
              navigate("/login");
            }, 1500);
          }
        })
        .catch((error) => {
          fireToast("error", error.response?.data.error);
          console.log(error);
          setLoader(false);
        });
    },
  });

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <div className="container-fluid">
          <div className="setting-wrapper ">
            <div className="row border-bottom my-4 pb-4">
              <div className="col-xl-12 p-4">
                <form autocomplete="off" onSubmit={formik.handleSubmit}>
                  <Form.Group>
                    <Form.Label>Profile </Form.Label>
                    <div className="uploadImageContainer mb-4">
                      <img
                        src={image == null || image === "" ? Dummy : image}
                        alt="Avatar"
                        className="image"
                        style={{
                          height: "100px",
                          objectFit: "contain",
                          width: "100px",
                        }}
                      />
                      <div className="overlay">
                        <label htmlFor="fileUploader" className="overlayBtn">
                          <BsPencilSquare />
                        </label>
                        <span
                          className="overlayBtn"
                          onClick={() => setImage("")}
                        >
                          <MdDeleteForever />
                        </span>
                      </div>
                      <input
                        type="file"
                        id="fileUploader"
                        onChange={editUploadedFile}
                        hidden
                      />
                    </div>
                  </Form.Group>
                  <div className="row">
                    <Form.Group
                      className="col-lg-6 col-12 mb-4"
                      controlId="formBasicEmail"
                    >
                      <Form.Label>First Name</Form.Label>
                      <div className="position-relative">
                        <Form.Control
                          type="text"
                          name="name"
                          id="name"
                          placeholder="Enter name"
                          value={formik.values.name}
                          onChange={formik.handleChange}
                        />
                        <i className="edit-icon">
                          <FaPen />
                        </i>
                      </div>
                    </Form.Group>
                    <Form.Group
                      className="col-lg-6 col-12 mb-4"
                      controlId="formBasicEmail"
                    >
                      <Form.Label>Last Name</Form.Label>
                      <div className="position-relative">
                        <Form.Control
                          type="text"
                          name="lastname"
                          id="lastname"
                          placeholder=""
                          value={formik.values.lastname}
                          onChange={formik.handleChange}
                        />
                        <i className="edit-icon">
                          <FaPen />
                        </i>
                      </div>
                    </Form.Group>

                    <Form.Group
                      className="col-lg-6 col-12 mb-4"
                      controlId="formBasicEmail"
                    >
                      <Form.Label>Username</Form.Label>
                      <div className="position-relative">
                        <Form.Control
                          type="text"
                          name="userName"
                          id="userName"
                          autocomplete="off"
                          value={formik.values.userName}
                          onChange={formik.handleChange}
                        />
                      </div>
                    </Form.Group>
                    <Form.Group
                      className="col-lg-6 col-12 mb-4"
                      controlId="formBasicEmail"
                    >
                      <Form.Label>User-Email</Form.Label>
                      <div className="position-relative">
                        <Form.Control type="text" disabled value={email} />
                        {email && (
                          <i
                            className="edit-icon "
                            onClick={() => handleCopy(email)}
                          >
                            <FaCopy />
                          </i>
                        )}
                      </div>
                    </Form.Group>
                    <Form.Group
                      controlId="formBasicEmail"
                      className="col-lg-6 col-12 mb-4"
                    >
                      <Form.Label className="form-label">Birth Date</Form.Label>
                      <Form.Control
                        className="form-ctl "
                        name="birth_date"
                        type="date"
                        value={formik.values.birth_date?.split("T")?.[0]}
                        onChange={formik.handleChange}
                      />
                      {formik.touched.birth_date && formik.errors.birth_date ? (
                        <div className="text-danger error-msg">
                          {formik.errors.birth_date}
                        </div>
                      ) : null}
                    </Form.Group>
                    <Form.Group
                      className="col-lg-6 col-12 mb-4"
                      controlId="formBasicPassword"
                    >
                      <Form.Label className="form-label">
                        Phone Number
                        {/* <span style={{ fontSize: "xx-small" }}>(enter without phone code)</span> */}
                      </Form.Label>
                      <div className="position-relative">
                        <PhoneInput
                          className="form-ctl form-control"
                          defaultCountry="US"
                          countryCallingCodeEditable={false}
                          placeholder="Enter Phone Number"
                          international
                          value={`${formik.values.countryCode}${formik.values.contact}`}
                          numberInputProps={{ name: "contact" }}
                          onChange={(contact) => {
                            let data = parsePhoneNumber(`${contact}`);
                            console.log(data);
                            formik.setFieldTouched("contact", true);
                            formik.setFieldTouched("countryCode", true);
                            formik.setFieldValue(
                              "contact",
                              data?.nationalNumber
                            );
                            formik.setFieldValue(
                              "countryCode",
                              `+${data?.countryCallingCode}`
                            );
                          }}
                        />
                        <i className="edit-icon">
                          <FaPen />
                        </i>
                      </div>

                      {formik.touched.contact && formik.errors.contact ? (
                        <div className="text-danger error-msg">
                          {formik.errors.contact}
                        </div>
                      ) : null}
                    </Form.Group>
                    <Form.Group
                      className="col-lg-6 col-12 mb-4"
                      controlId="formBasicPassword"
                    >
                      <Form.Label>Address Line 1</Form.Label>
                      <div className="position-relative">
                        <Form.Control
                          name="street_line_1"
                          placeholder={"Enter Address Line 1"}
                          type="text"
                          value={formik.values.street_line_1}
                          onChange={formik.handleChange}
                        />
                        <i className="edit-icon">
                          <FaPen />
                        </i>
                      </div>
                      {formik.touched.street_line_1 &&
                      formik.errors.street_line_1 ? (
                        <div className="text-danger error-msg">
                          {formik.errors.street_line_1}
                        </div>
                      ) : null}
                    </Form.Group>
                    <Form.Group
                      className="col-lg-6 col-12 mb-4"
                      controlId="formBasicPassword"
                    >
                      <Form.Label>Address Line 2</Form.Label>
                      <div className="position-relative">
                        <Form.Control
                          name="street_line_2"
                          placeholder={"Enter Address Line 2"}
                          type="text"
                          value={formik.values.street_line_2}
                          onChange={formik.handleChange}
                        />
                        <i className="edit-icon">
                          <FaPen />
                        </i>
                      </div>
                      {formik.touched.street_line_2 &&
                      formik.errors.street_line_2 ? (
                        <div className="text-danger error-msg">
                          {formik.errors.street_line_2}
                        </div>
                      ) : null}
                    </Form.Group>
                    {/* <Form.Group controlId="formBasicEmail" className="col-lg-2 col-4 mb-4">
                    <Form.Label className="form-label">Phone Code</Form.Label>
                    <div className="position-relative">
                      <i className="edit-icon">
                        <FaPen />
                      </i>
                      <Form.Control
                        className="form-ctl "
                        name="countryCode"
                        placeholder={"Enter Phone Code"}
                        type="text"
                        value={formik.values.countryCode}
                        onChange={formik.handleChange}
                      />
                    </div>
                    {formik.touched.countryCode &&
                      formik.errors.countryCode ? (
                      <div className="text-danger error-msg">
                        {formik.errors.countryCode}
                      </div>
                    ) : null}
                  </Form.Group>

                  <Form.Group
                    className="col-lg-4 col-8 mb-4"
                    controlId="formBasicPassword"
                  >
                    <Form.Label>Phone Number <span style={{ fontSize: "xx-small" }}>(enter without phone code)</span></Form.Label>
                    <div className="position-relative">
                      <Form.Control
                        name="contact"
                        maxLength={10}
                        placeholder={"Enter Contact Number"}
                        type="text"
                        value={formik.values.contact}
                        onChange={formik.handleChange}
                      />
                      <i className="edit-icon">
                        <FaPen />
                      </i>
                    </div>
                    {formik.touched.contact &&
                      formik.errors.contact ? (
                      <div className="text-danger error-msg">
                        {formik.errors.contact}
                      </div>
                    ) : null}
                  </Form.Group> */}

                    <Form.Group
                      className="col-lg-6 col-12 mb-4"
                      controlId="formBasicPassword"
                    >
                      <Form.Label>City</Form.Label>
                      <div className="position-relative">
                        <Form.Control
                          name="city"
                          placeholder={"Enter City"}
                          type="text"
                          value={formik.values.city}
                          onChange={formik.handleChange}
                        />
                        <i className="edit-icon">
                          <FaPen />
                        </i>
                      </div>
                      {formik.touched.city && formik.errors.city ? (
                        <div className="text-danger error-msg">
                          {formik.errors.city}
                        </div>
                      ) : null}
                    </Form.Group>
                    <Form.Group
                      className="col-lg-6 col-12 mb-4"
                      controlId="formBasicPassword"
                    >
                      <Form.Label className="form-label">
                        State{" "}
                        <span style={{ fontSize: "xx-small" }}>
                          (2 letter of code example : NY)
                        </span>
                      </Form.Label>
                      <div className="position-relative">
                        <Form.Control
                          className="form-ctl text-uppercase"
                          name="state"
                          maxLength={2}
                          value={formik.values.state}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {/* <option value={""}>Select State</option>
                        {stateOptions}
                      </Form.Select> */}
                        <i className="edit-icon">
                          <FaPen />
                        </i>
                      </div>
                      {formik.touched.state && formik.errors.state ? (
                        <div className="text-danger error-msg">
                          {formik.errors.state}
                        </div>
                      ) : null}
                    </Form.Group>

                    {/* <Form.Group
                    className="col-lg-3 col-6 mb-4"
                    controlId="formBasicPassword"
                  >
                    <Form.Label>Country <span style={{ fontSize: "xx-small" }}>(3 letter of code example : USA)</span></Form.Label>
                    <div className="position-relative">
                      <Form.Control
                        name="countryShortCode"
                        placeholder={"Enter Country"}
                        type="text"
                        value={formik.values.countryShortCode}
                        onChange={formik.handleChange}
                      />
                      <i className="edit-icon">
                        <FaPen />
                      </i>
                    </div>
                    {formik.touched.countryShortCode &&
                      formik.errors.countryShortCode ? (
                      <div className="text-danger error-msg">
                        {formik.errors.countryShortCode}
                      </div>
                    ) : null}
                  </Form.Group>
                  <Form.Group
                    className="col-lg-3 col-6 mb-4"
                    controlId="formBasicPassword"
                  >
                    <Form.Label>State <span style={{ fontSize: "xx-small" }}>(2 letter of code example : CH)</span></Form.Label>
                    <div className="position-relative">
                      <Form.Control
                        name="state"
                        placeholder={"Enter State"}
                        type="text"
                        value={formik.values.state}
                        onChange={formik.handleChange}
                      />
                      <i className="edit-icon">
                        <FaPen />
                      </i>
                    </div>
                    {formik.touched.state &&
                      formik.errors.state ? (
                      <div className="text-danger error-msg">
                        {formik.errors.state}
                      </div>
                    ) : null}
                  </Form.Group> */}

                    <Form.Group
                      className="col-lg-6 col-12 mb-4"
                      controlId="formBasicPassword"
                    >
                      <Form.Label>Postal Code</Form.Label>
                      <div className="position-relative">
                        <Form.Control
                          name="postal_code"
                          placeholder={"Enter Postal Code"}
                          type="text"
                          value={formik.values.postal_code}
                          onChange={formik.handleChange}
                        />
                        <i className="edit-icon">
                          <FaPen />
                        </i>
                      </div>
                      {formik.touched.postal_code &&
                      formik.errors.postal_code ? (
                        <div className="text-danger error-msg">
                          {formik.errors.postal_code}
                        </div>
                      ) : null}
                    </Form.Group>
                    <Form.Group
                      className="col-lg-6 col-12 mb-4"
                      controlId="formBasicPassword"
                    >
                      <Form.Label className="form-label">
                        Country{" "}
                        <span style={{ fontSize: "xx-small" }}>
                          (3 letter of code example : USA)
                        </span>
                      </Form.Label>
                      <div className="position-relative">
                        <Form.Control
                          aria-label="Default select example"
                          className="form-ctl text-uppercase"
                          name="countryShortCode"
                          maxLength={3}
                          value={formik.values.countryShortCode}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {/* <option value={""}>Select Country</option>
                        {countryOptions} */}
                        {/* </Form.Select> */}
                        <i className="edit-icon">
                          <FaPen />
                        </i>
                      </div>

                      {formik.touched.countryShortCode &&
                      formik.errors.countryShortCode ? (
                        <div className="text-danger error-msg">
                          {formik.errors.countryShortCode}
                        </div>
                      ) : null}
                    </Form.Group>
                    <Form.Group
                      className="col-lg-6 col-12 mb-4"
                      controlId="formBasicPassword"
                    >
                      <Form.Label>SSN or Taxpayer ID</Form.Label>
                      <div className="position-relative">
                        <Form.Control
                          name="tax_identification_number"
                          autoComplete="off"
                          placeholder={"Enter SSN or Taxpayer ID"}
                          type={tinPass}
                          value={formik.values.tax_identification_number}
                          onChange={formik.handleChange}
                        />
                        {tinPass === "password" ? (
                          <span>
                            <AiOutlineEye
                              onClick={() => setTinPass("text")}
                              className="edit-icon"
                            />{" "}
                          </span>
                        ) : (
                          <span>
                            <AiOutlineEyeInvisible
                              onClick={() => setTinPass("password")}
                              className="edit-icon"
                            />{" "}
                          </span>
                        )}
                      </div>
                      {formik.touched.tax_identification_number &&
                      formik.errors.tax_identification_number ? (
                        <div className="text-danger error-msg">
                          {formik.errors.tax_identification_number}
                        </div>
                      ) : null}
                    </Form.Group>

                    <Form.Group
                      className="col-lg-6 col-12 mb-4"
                      controlId="formBasicEmail"
                    >
                      <Form.Label>Solos Wallet Address </Form.Label>
                      <div className="position-relative">
                        <Form.Control
                          type="text"
                          name="walletAddress"
                          id="walletAddress"
                          disabled
                          placeholder="Enter wallet address"
                          value={formik.values.walletAddress}
                          onChange={formik.handleChange}
                        />
                        <i
                          className="edit-icon "
                          onClick={() =>
                            handleCopy(formik.values.walletAddress)
                          }
                        >
                          <FaCopy />
                        </i>
                      </div>
                    </Form.Group>
                    <Form.Group
                      className="col-lg-6 col-12 mb-4"
                      controlId="formBasicEmail"
                    >
                      <Form.Label>Solos ID</Form.Label>
                      <div className="position-relative">
                        <Form.Control
                          type="text"
                          id="solosID"
                          disabled
                          value={data?.solosID}
                        />
                        <i
                          className="edit-icon "
                          onClick={() => handleCopy(data?.solosID)}
                        >
                          <FaCopy />
                        </i>
                      </div>
                    </Form.Group>
                    <Form.Group
                      className="col-lg-6 col-12 mb-4"
                      controlId="formBasicEmail"
                    >
                      <Form.Label>Role</Form.Label>
                      <div className="position-relative">
                        <Form.Control
                          type="text"
                          id="solosID"
                          disabled
                          className="text-uppercase"
                          value={data?.role}
                        />
                        <i
                          className="edit-icon "
                          onClick={() => handleCopy(data?.role)}
                        >
                          <FaCopy />
                        </i>
                      </div>
                    </Form.Group>
                    <Form.Group
                      className="col-lg-6 col-12 mb-4"
                      controlId="formBasicEmail"
                    >
                      <Form.Label>My Referral Code</Form.Label>
                      <div className="position-relative">
                        <Form.Control type="text" disabled value={myRef} />
                        {myRef && (
                          <i
                            className="edit-icon "
                            onClick={() => handleCopy(myRef)}
                          >
                            <FaCopy />
                          </i>
                        )}
                      </div>
                    </Form.Group>
                    <Form.Group
                      className="col-lg-6 col-12 mb-4"
                      controlId="formBasicEmail"
                    >
                      <Form.Label>REFER A FRIEND</Form.Label>
                      <div className="position-relative">
                        <Form.Control
                          type="text"
                          disabled
                          value={`${window.location.host}/register?refer-by=${myRef}`}
                        />
                        {myRef && (
                          <i
                            className="edit-icon "
                            onClick={() =>
                              handleCopy(
                                `${window.location.host}/register?refer-by=${myRef}`
                              )
                            }
                          >
                            <FaCopy />
                          </i>
                        )}
                      </div>
                    </Form.Group>

                    {refBy && refBy !== "undefined" && (
                      <h6>
                        Referred By-{" "}
                        <span style={{ color: "#000" }}>{refBy}</span>
                      </h6>
                    )}
                  </div>
                  <div className="col-md-12 text-center">
                    <Button
                      disabled={loader}
                      className="mx-auto px-5 common-btn1"
                      variant="primary"
                      type="submit"
                    >
                      Save
                    </Button>
                  </div>
                </form>
              </div>
              <div className="col-xl-12 mt-5">
                <div className="card p-4">
                  <h5 className="mb-3">Change Password</h5>
                  <form onSubmit={passwordFormik.handleSubmit}>
                    <div className="row">
                      <Form.Group
                        className="col-lg-6 col-12 mb-4"
                        controlId="formBasicEmail"
                      >
                        <Form.Label>Old Password</Form.Label>
                        <div className="position-relative">
                          <Form.Control
                            type={passwordType}
                            name="oldPassword"
                            autoComplete="new-password"
                            id="oldPassword"
                            placeholder="Enter Old Password"
                            value={passwordFormik.values.oldPassword}
                            onChange={passwordFormik.handleChange}
                            invalid={
                              passwordFormik.touched.oldPassword &&
                              passwordFormik.errors.oldPassword
                                ? true
                                : false
                            }
                          />
                          {passwordType === "password" ? (
                            <span>
                              <AiOutlineEye
                                onClick={() => setPasswordType("text")}
                                className="view_setting_password"
                              />{" "}
                            </span>
                          ) : (
                            <span>
                              <AiOutlineEyeInvisible
                                onClick={() => setPasswordType("password")}
                                className="view_setting_password"
                              />{" "}
                            </span>
                          )}
                        </div>
                        {passwordFormik.touched.oldPassword &&
                        passwordFormik.errors.oldPassword ? (
                          <div className="text-danger error-text">
                            {passwordFormik.errors.oldPassword}
                          </div>
                        ) : null}
                      </Form.Group>
                      <Form.Group
                        className="col-lg-6 col-12 mb-4"
                        controlId="formBasicEmail"
                      >
                        <Form.Label>New Password</Form.Label>
                        <div className="position-relative">
                          <Form.Control
                            type={newPasswordType}
                            name="newPassword"
                            autoComplete="new-password"
                            id="newPassword"
                            placeholder="Enter New Password"
                            value={passwordFormik.values.newPassword}
                            onChange={passwordFormik.handleChange}
                          />
                          {newPasswordType === "password" ? (
                            <span>
                              <AiOutlineEye
                                onClick={() => setnewPasswordType("text")}
                                className="view_setting_password"
                              />{" "}
                            </span>
                          ) : (
                            <span>
                              <AiOutlineEyeInvisible
                                onClick={() => setnewPasswordType("password")}
                                className="view_setting_password"
                              />{" "}
                            </span>
                          )}
                        </div>
                        {passwordFormik.touched.newPassword &&
                        passwordFormik.errors.newPassword ? (
                          <div className="text-danger error-text">
                            {passwordFormik.errors.newPassword}
                          </div>
                        ) : null}
                      </Form.Group>
                    </div>

                    <div className="col-md-12 text-center">
                      <Button
                        disabled={loader}
                        className="mx-auto px-5 common-btn1"
                        variant="primary"
                        type="submit"
                      >
                        Save
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-xl-12 mt-5">
                <Mpin
                  loader={loader}
                  setLoader={setLoader}
                  isMpin={isMpin}
                  isPinActive={isPinActive}
                  getdata={getdata}
                />
              </div>
            </div>

            <div className="row border-bottom my-4 pb-4 d-none">
              <div className="col-12 mb-4 pt-2">
                <h6 className="fw-500 text-black">Add Account</h6>
              </div>
              <div className="col-xl-6">
                <Form className="row user-form">
                  <Form.Group
                    className="col-md-6 col-12 mb-4"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>Email address</Form.Label>
                    <div className="position-relative">
                      <Form.Control type="text" placeholder="Enter email" />
                      <i className="edit-icon">
                        <FaPen />
                      </i>
                    </div>
                  </Form.Group>

                  <Form.Group
                    className="col-md-6 col-12 mb-4"
                    controlId="formBasicPassword"
                  >
                    <Form.Label>Password</Form.Label>
                    <div className="position-relative">
                      <Form.Control type="email" placeholder="Password" />
                      <i className="edit-icon">
                        <FaPen />
                      </i>
                    </div>
                  </Form.Group>
                  <Form.Group
                    className="col-md-6 col-12 mb-4"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>Username</Form.Label>
                    <div className="position-relative">
                      <Form.Control type="text" placeholder="Enter email" />
                      <i className="edit-icon">
                        <FaPen />
                      </i>
                    </div>
                  </Form.Group>

                  <Form.Group
                    className="col-md-6 col-12 mb-4"
                    controlId="formBasicPassword"
                  >
                    <Form.Label>Phone Number</Form.Label>
                    <div className="position-relative">
                      <CountryFlag />
                      <i className="edit-icon">
                        <FaPen />
                      </i>
                    </div>
                  </Form.Group>
                  <div className="col-md-6 col-12">
                    <Button
                      className="w-100 common-btn1"
                      variant="primary"
                      type="submit"
                    >
                      Save
                    </Button>
                  </div>
                </Form>
              </div>
            </div>

            <div className="row border-bottom my-4 pb-4 d-none">
              <div className="col-12 mb-3">
                <h6 className="fw-500 text-black">Invite Friend</h6>
              </div>
              <div className="col-xl-6">
                <Form className="row user-form">
                  <Form.Group className="col-md-6 col-12" controlId="">
                    <Form.Control type="text" placeholder="KJKHHUIDNNJALK" />
                  </Form.Group>
                </Form>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Settings;

/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { FaRegEnvelope } from "react-icons/fa";
import logo from "../images/r-logo.svg";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { forgetPassword } from "../services/userService";
import { fireToast } from "../common/Toster";

const ForgotEmail = () => {
  // const [email, setEmail] = useState("");
  let navigate = useNavigate();
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid User")
        .required("This Field is Required"),
    }),
    onSubmit: async (values) => {
      try {
        // changeApiStatus(true, "");
        const loginResponse = await forgetPassword(values);
        if (loginResponse.status === 200) {
          // console.log(loginResponse, "loginResponse");
          localStorage.setItem("forgot-data", values?.email);
          fireToast("success", loginResponse?.message);
          // changeApiStatus(false, "");
          navigate("/forgotOtp");
        } else {
          // changeApiStatus(false, "");
          throw new Error(loginResponse?.error);
        }
      } catch (err) {
        fireToast("error", err?.response?.data?.error);
        // changeApiStatus(false, "");
      }
    },
  });
  return (
    <div className="auth-bg forgot-mail mh-100 d-flex align-items-center justify-content-center">
      <div className="auth-card py-lg-3">
        <div className="auth-inner">
          <div className="text-center mb-4 mb-lg-5">
            <Link to="/">
              <img className="mx-auto" src={logo} alt="" />
            </Link>
          </div>

          <h1 className="fs-37 mb-4">Forgot Password</h1>

          <Form
            // className="form-horizontal"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <div className="mb-3">
              <Form.Group>
                <Form.Label className="form-label">Email</Form.Label>
                <Form.Control
                  name="email"
                  placeholder={"Enter Email"}
                  type="email"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.email || ""}
                  invalid={
                    validation.touched.email && validation.errors.email
                      ? "true"
                      : "false"
                  }
                />
                {validation.touched.email && validation.errors.email ? (
                  // <Form.Control.Feedback type="invalid">
                  //   {validation.errors.email}
                  // </Form.Control.Feedback>
                  <div className="text-danger">{validation.errors.email}</div>
                ) : null}
              </Form.Group>
            </div>

            <div className="mt-3 d-grid">
              <Button
                className="common-btn mt-4 mb-3 w-100"
                variant="primary"
                type="submit"
              >
                <strong>Submit</strong>
              </Button>
            </div>
          </Form>

          {/* <Form>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Email</Form.Label>
              <div className="input-inner position-relative">
                <Form.Control type="email" placeholder="Please Enter the Email" />
                <span className="input-icon">
                  <FaRegEnvelope />
                </span>
              </div>
            </Form.Group>

            <Button
              className="common-btn mt-4 mb-3"
              variant="primary"
              type="submit"
            >
              Submit
            </Button>
           
          </Form> */}
        </div>
      </div>
    </div>
  );
};

export default ForgotEmail;

/* eslint-disable no-unused-vars */
import React, { useContext, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { ConversionContext } from "../Context/context";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import {
  createChangeMpin,
  enableDisableSecurity,
} from "../services/propertyServices";
import { fireToast } from "../common/Toster";

const Mpin = ({ isMpin, isPinActive, loader, setLoader, getdata }) => {
  const contextData = useContext(ConversionContext);
  const getUserData = contextData?.getUserData;
  const navigate = useNavigate();
  const [passwordType, setpasswordType] = useState("password");
  const [mpinType, setMpinType] = useState("password");
  const mpinFormik = useFormik({
    initialValues: {
      mpin: "",
      password: "",
    },
    validationSchema: Yup.object({
      mpin: Yup.string()
        // eslint-disable-next-line no-useless-escape
        .matches(/^\d*[\.{1}\d*]\d*$/, "Must be only digits")
        .required("This Field is Required")
        .min(4, "PIN must be 4 characters long")
        .max(4, "PIN must be 4 characters long"),
      password: Yup.string().required("This Field is Required"),
    }),
    onSubmit: (data, { resetForm }) => {
      createChangeMpin(
        isMpin
          ? {
              mpin: data?.mpin,
              password: data?.password,
              isMpinUsedForTransactions: isPinActive,
            }
          : {
              mpin: data?.mpin,
              password: data?.password,
              isMpinUsedForTransactions: false,
            }
      )
        .then((response) => {
          if (response.status === 200) {
            fireToast("success", "Successfully updated!");
            getdata();
            resetForm();
            setLoader(false);
          }
        })
        .catch((error) => {
          fireToast("error", error.response?.data.error);
          console.log(error);
          setLoader(false);
        });
    },
  });

  const changeStatus = async (e) => {
    console.log(e.target.checked);
    setLoader(true);
    const response = await enableDisableSecurity({
      type: "isMpinActive",
      value: e.target.checked,
    })
      .then((response) => {
        if (response.status === 200) {
          fireToast("success", "Successfully updated!");
          getdata();
          getUserData();
          setLoader(false);
        }
      })
      .catch((error) => {
        fireToast("error", error.response?.data.error);
        console.log(error);
        setLoader(false);
      });
  };

  return (
    <div className="card p-4">
      <h5 className="mb-3">{isMpin ? "Change PIN" : "Create PIN"}</h5>
      {isMpin && (
        <div className="d-flex my-2">
          <label className="">Use PIN for transactions :</label>

          <Form.Check
            className="mx-4"
            defaultChecked={isPinActive}
            type="switch"
            id="custom-switch"
            //   label="Use PIN for transactions"
            onChange={(e) => changeStatus(e)}
          />
        </div>
      )}
      <form onSubmit={mpinFormik.handleSubmit}>
        <div className="row">
          <Form.Group
            className="col-lg-6 col-12 mb-4"
            controlId="formBasicEmail"
          >
            <Form.Label>PIN</Form.Label>
            <div className="position-relative">
              <Form.Control
                type={mpinType}
                name="mpin"
                autoComplete="new-password"
                id="mpin"
                placeholder="Enter PIN"
                value={mpinFormik.values.mpin}
                onChange={mpinFormik.handleChange}
                invalid={
                  mpinFormik.touched.mpin && mpinFormik.errors.mpin
                    ? true
                    : false
                }
              />
              {mpinType === "password" ? (
                <span>
                  <AiOutlineEye
                    onClick={() => setMpinType("text")}
                    className="view_setting_password"
                  />{" "}
                </span>
              ) : (
                <span>
                  <AiOutlineEyeInvisible
                    onClick={() => setMpinType("password")}
                    className="view_setting_password"
                  />{" "}
                </span>
              )}
            </div>
            {mpinFormik.touched.mpin && mpinFormik.errors.mpin ? (
              <div className="text-danger error-text">
                {mpinFormik.errors.mpin}
              </div>
            ) : null}
          </Form.Group>
          <Form.Group
            className="col-lg-6 col-12 mb-4"
            controlId="formBasicEmail"
          >
            <Form.Label>Password</Form.Label>
            <div className="position-relative">
              <Form.Control
                type={passwordType}
                name="password"
                autoComplete="new-password"
                id="password"
                placeholder="Enter Password"
                value={mpinFormik.values.password}
                onChange={mpinFormik.handleChange}
              />
              {passwordType === "password" ? (
                <span>
                  <AiOutlineEye
                    onClick={() => setpasswordType("text")}
                    className="view_setting_password"
                  />{" "}
                </span>
              ) : (
                <span>
                  <AiOutlineEyeInvisible
                    onClick={() => setpasswordType("password")}
                    className="view_setting_password"
                  />{" "}
                </span>
              )}
            </div>
            {mpinFormik.touched.password && mpinFormik.errors.password ? (
              <div className="text-danger error-text">
                {mpinFormik.errors.password}
              </div>
            ) : null}
          </Form.Group>
        </div>

        <div className="col-md-12 text-center">
          <Button
            disabled={loader}
            className="mx-auto px-5 common-btn1"
            variant="primary"
            type="submit"
          >
            Save
          </Button>
        </div>
      </form>
    </div>
  );
};

export default Mpin;

/* eslint-disable no-unused-vars */
import React from "react";
import "./Usdc.css";
import Solos from "../../images/newcardlogo.svg";
// import SendUsdc from "./SendUsdc";
// import ReceiveUsdc from "./ReceiveUsdc";

const UsdcCard = ({ UsdcTokenData, getTokenBalance, userInfo }) => {
  const UsdcToken = UsdcTokenData;
  const [showSend, setShowSend] = React.useState(false);
  const [showReceive, setShowReceive] = React.useState(false);

  return (
    <>
      {/* <SendUsdc getTokenBalance={getTokenBalance} showSend={showSend} setShowSend={setShowSend} />
      <ReceiveUsdc userInfo={userInfo} showReceive={showReceive} setShowReceive={setShowReceive} /> */}
      <div
        className="token-card usdc-token d-flex flex-column justify-content-between position-relative"
        style={{ cursor: "pointer" }}
      >
        <div className="d-flex align-items-center justify-content-center">
          <img src={Solos} alt="" height={30} />
        </div>
        <div>
          <h6>
            <span className="text-white fw-400 scard-title">USDC Tokens : </span>
            <span className="text-white text-start ps-1 scard-value">
              $
              {UsdcToken
                ? Number(
                  Number(UsdcToken?.balance)
                    .toString()
                    .match(/^-?\d+(?:.\d{0,2})?/)[0]
                ).toLocaleString()
                : 0}
            </span>
          </h6>
        </div>
        {/* <div className="token-head d-flex align-items-center justify-content-between">
          <div>
            <span className="text-white fw-400 scard-title">USDC Tokens</span>
            <h6 className="fs-24 text-white text-start ps-1">
              $
              {UsdcToken
                ? Number(
                  Number(UsdcToken?.balance)
                    .toString()
                    .match(/^-?\d+(?:.\d{0,2})?/)[0]
                ).toLocaleString()
                : 0}
            </h6>
          </div>
        </div> */}
        <div className="token-bottom">
          {/* <div
            className="row d-flex align-items-center text-center"
          >
            <span onClick={() => setShowSend(true)} className="col-3 justify-content-center text-center fs-8 mb-0 text-white fw-500 cursor-pointer">
              <span className="token-btn d-flex align-items-center justify-content-center mx-auto mb-0 receive-tkn position-relative mb-1">
                <img src={send} alt="" />
              </span>
              <div className="text-white scard-btext">Send</div>
            </span>
            <span onClick={() => setShowReceive(true)} className="col-3 justify-content-center text-center fs-8 mb-0 text-white fw-500 cursor-pointer">
              <span className="token-btn d-flex align-items-center justify-content-center mx-auto mb-0 receive-tkn position-relative mb-1">
                <img src={Receive} alt="" />
              </span>
              <div className="text-white scard-btext">Receive</div>
            </span>
            <span
              className="col-3 justify-content-center text-center fs-8 mb-0 text-white fw-500 cursor-pointer"
            >
              <span className="token-btn d-flex align-items-center justify-content-center mx-auto mb-1">
                <img src={Buyicon} alt="" />
              </span>
              <p className="scard-btext">Buy</p>
            </span>
            <span
              className="col-3 justify-content-center text-center fs-8 mb-0 text-white fw-500 cursor-pointer"
            >
              <span className="token-btn d-flex align-items-center justify-content-center mx-auto mb-1">
                <img src={send} alt="" />
              </span>
              <p className="scard-btext">Sell</p>
            </span>
          </div> */}
        </div>
        {/* <div
          className="text-center"
          style={{ position: "absolute", top: "20px", right: "18px" }}
        >
          <img src={Solos} alt="" height={22} />
        </div> */}
      </div>
    </>
  );
};

export default UsdcCard;
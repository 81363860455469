/* eslint-disable no-unused-vars */
import React, { useState, useContext, useEffect } from "react";
import TopNav from "../top-nav/TopNav";
import DashboardLeft from "../../pages/DashboardLeft";
import BasicInfo from "../../auth/BasicInfo";
import { Outlet, useLocation } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { getUserProfile } from "../../services/userService";
import { ConversionContext } from "../../Context/context";
import Kyc from "../../auth/Kyc/Kyc";

export function Layout({ children }) {
  const location = useLocation();
  const { showKyc, setShowKyc, activeSwapTab } = useContext(ConversionContext);
  const pathname = location?.pathname;
  let lastSegment = pathname.split("/").pop();
  lastSegment = lastSegment?.toLowerCase() === 'swap' ? activeSwapTab : lastSegment;
  const [custom, setCustom] = useState(false);
  const [data, setData] = useState({});
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    setShowKyc(false);
  };
  const getUserData = async () => {
    try {
      const response = await getUserProfile();
      setData(response?.data);
      setShow(response?.data?.name == null ? true : false);
    } catch (error) {
      console.error("Error fetching nft data:", error);
    }
  };

  useEffect(() => {
    getUserData();
  }, []);
  return (
    <>
      <div className={`dashboard-page d-flex ${custom ? "smallSidebar" : ""}`}>
        <div className="dashboard-left">
          <DashboardLeft />
        </div>
        <div className="dashboard-right">
          <TopNav setCustom={setCustom} custom={custom} title={lastSegment} />
          <div className="w-full">
            <Outlet />
          </div>
        </div>
      </div>
      <div>
        {/* facetec kyc hide */}
        <Modal
          className="layout-modal"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          // show={show || showKyc}
          show={show}
          backdrop="static"
          onHide={handleClose}
          animation={false}
        >
          <Modal.Body>
            {/* facetec kyc hide */}
            {/* {showKyc ? <Kyc /> : <BasicInfo setShow={setShow} />} */}
            <BasicInfo setShow={setShow} />
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}
export const getDashboardLayout = function (page, pageProps) {
  return <Layout {...pageProps}>{page}</Layout>;
};

export default Layout;

/* eslint-disable no-unused-vars */
import React, { useContext, useState } from 'react'
import { ConversionContext } from '../../Context/context';
import { fierceErrorPopup, fierceTransactionPopup, fireSuccessToast, fireToast } from '../../common/Toster';
import { buyRedFromFierce, canPerformTransaction, postFierceTransaction } from '../../services/propertyServices';
import configURl from '../../runtime.config';
import { humanReadableNumber } from '../../common/utility';
import { PiArrowsDownUpFill } from 'react-icons/pi';
import { useActiveAccount } from 'thirdweb/react';
import { getContract, sendAndConfirmTransaction, sendTransaction } from 'thirdweb';
import { polygon } from 'thirdweb/chains';
import { client } from '../../runtime.config';
import { balanceOf, decimals, transfer } from 'thirdweb/extensions/erc20';
import { ethers } from 'ethers';
function BuyTab() {
    const activeAccount = useActiveAccount();
    const [inputType, setInputType] = useState("usd");
    const [amount, setAmount] = useState("");
    const [redToken, setRedToken] = useState("");
    const contract = getContract({
        address: configURl.redNewAddress,
        chain: polygon,
        client,
    });

    const { fetchAssets, redTokenData, fierceLoader, adminWalletAddress, activeFierceTab, setFierceLoader, getCustomerBalance, setActiveFierceTab, conversionRate, fierceId, solosId, availableBalance } = useContext(ConversionContext);

    const sellRedFromFierce = async () => {
        try {
            if (amount <= 0) {
                fireToast("error", "please enter amount...");
            } else {
                setFierceLoader(true);
                // const balance = await contract.erc20.balanceOf(activeAccount?.address);

                const balance = ethers.utils.formatUnits(
                    await balanceOf({
                        contract: contract,
                        address: activeAccount?.address
                    }),
                    await decimals({
                        contract: contract
                    })
                );


                if (+balance < redToken) {
                    // console.log(error, "balance error");
                    fireToast("error", "Transfer amount exceeds balance !");
                    setFierceLoader(false);
                    return;
                }

                // const tx = await contract.erc20.transfer(adminWalletAddress, redToken);
                // const receipt = tx.receipt;

                const transaction = transfer({
                    contract: contract,
                    to: adminWalletAddress,
                    amount: +redToken
                });
                const receipt = await sendAndConfirmTransaction({
                    transaction: transaction,
                    account: activeAccount,
                    ...(configURl?.isLive ? {
                        gasless: {
                            provider: 'engine',
                            relayerUrl: configURl?.relayerUrl,
                            relayerForwarderAddress: configURl?.relayerForwarderAddress
                        }
                    } : null)
                });

                if (receipt?.transactionHash) {
                    const requestData = {
                        solosId,
                        fierceId,
                        walletAddress: activeAccount?.address,
                        redAmount: redToken,
                        usdAmount: amount,
                        hashId: receipt?.transactionHash,
                        transactionType: "SELL",
                    }
                    await postFierceTransaction(requestData).then((response) => {
                        setFierceLoader(false);
                        if (response?.data?.data?.errorMessage) {
                            fireToast("error", response?.data?.data?.errorMessage);
                        } else {
                            setRedToken("");
                            setAmount("");
                            // fireSuccessToast("Transaction Posted Successfully !");
                            fierceTransactionPopup();
                        }
                    }).catch((error) => {
                        setFierceLoader(false);
                        console.log(error);
                        fireToast("error", error?.response?.data?.data?.error);
                    }).finally(getCustomerBalance());
                    fetchAssets();
                }
            }
        } catch (error) {
            setFierceLoader(false);
            console.log(error);
        }
    }
    const buyRedFierce = async () => {
        try {
            if (+amount <= 0) {
                fireToast("error", "Please enter amount...");
            } else if (+amount > +availableBalance) {
                fireToast("error", "Please enter amount less than avaliable balance .");
            } else {
                setFierceLoader(true);
                buyRedFromFierce({
                    fierceId,
                    solosId,
                    amount: amount,
                })
                    .then((response) => {
                        console.table([{ method: 'post', url: '/fierce/buyRed', response: response?.data }]);
                        console.log(response.data);
                        if (response.data.status === 'Failed') {
                            setFierceLoader(false);
                            fireToast("error", response?.data?.errorMessage);
                        } else if (+response.data.amount > 0 && response.data.status !== 'Failed') {
                            const requestData = {
                                ...response.data,
                                walletAddress: activeAccount?.address,
                                redAmount: redToken,
                                usdAmount: amount,
                                transactionType: "BUY",
                                fierceStatus: response.data.status
                            }
                            postFierceTransaction(requestData).then((response) => {
                                setFierceLoader(false);
                                if (response?.data?.data?.errorMessage) {
                                    fireToast("error", response?.data?.data?.errorMessage);
                                } else {
                                    setRedToken("");
                                    setAmount("");
                                    // fireSuccessToast("Transaction Posted Successfully !");
                                    fierceTransactionPopup();
                                }
                            }).catch((error) => {
                                setFierceLoader(false);
                                console.log(error);
                                fireToast("error", error?.response?.data?.data?.error);
                            }).finally(getCustomerBalance());
                            fetchAssets();
                        }
                    }).catch((error) => {
                        setFierceLoader(false);
                        console.log(error);
                        fireToast("error", "Transaction failed!");
                    })
            }
        } catch (error) {
            setFierceLoader(false);
            console.log(error);
        }
    }
    const handleClick = async () => {
        try {
            setFierceLoader(true);
            const response = await canPerformTransaction();
            if (response.status === 200) {
                if (activeFierceTab === 'sell') {
                    await sellRedFromFierce()
                } else if (activeFierceTab === 'buy') {
                    await buyRedFierce()
                }
            } else {
                throw new Error(response.error);
            }
        } catch (error) {
            setFierceLoader(false);
            console.log(error);
            fierceErrorPopup(error?.response?.data?.error);
        }
    }
    const buyDisabled = !amount || +amount > +availableBalance;
    const sellDisabled = !redToken || +redToken > +redTokenData?.balance;
    const disabled = activeFierceTab === 'sell' ? sellDisabled : buyDisabled
    return (
        <div className="buy">
            <div className="swap-buy py-4">
                <div className='d-flex justify-content-between'>
                    <h6 className="text-start">You're {activeFierceTab}ing</h6>
                    <select
                        className="custom-select p-2 rounded"
                        value={activeFierceTab}
                        onChange={(e) => {
                            setActiveFierceTab(e.target.value)
                        }}>
                        <option className='p-1' value={'buy'}>Buy </option>
                        <option className='p-1' value={'sell'}>Sell </option>
                    </select>
                </div>

                <div className="enter-amount mt-1">
                    {
                        inputType !== 'usd' ?
                            <input
                                type="number"
                                className="form-control"
                                placeholder="0"
                                value={redToken}
                                onChange={(e) => {
                                    if (e.target.value.length <= 6) {
                                        const newUsdAmount = e.target.value * conversionRate;
                                        setRedToken(e.target.value);
                                        setAmount(newUsdAmount.toFixed(2));
                                    }
                                }}
                            ></input>
                            :
                            <input
                                type="number"
                                className="form-control"
                                placeholder="0"
                                value={amount}
                                onChange={(e) => {
                                    if (e.target.value.length <= 6) {
                                        const newRedAmount = e.target.value / conversionRate;
                                        setRedToken(newRedAmount.toFixed(2));
                                        setAmount(e.target.value);
                                    }
                                }}
                            ></input>
                    }

                    {inputType !== 'usd' ? (
                        <button type="button" className="btn btn-modal w-50">
                            $ {humanReadableNumber(amount)}
                            <PiArrowsDownUpFill className=" ms-1" onClick={() => {
                                setInputType('usd')
                            }} />
                        </button>
                    ) :
                        (
                            <button type="button" className="btn btn-modal w-50">
                                {humanReadableNumber(redToken)} {redTokenData.symbol}
                                <PiArrowsDownUpFill className=" ms-1" onClick={() => {
                                    setInputType('other')
                                }} />
                            </button>
                        )
                    }
                    {/* <button
                        type="button"
                        className="btn btn-modal"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                    >
                        <img alt="" src={redTokenData?.image}></img>
                        {redTokenData?.symbol}
                        <i className="fa fa-angle-down" aria-hidden="true"></i>
                    </button> */}
                </div>
                <p role="alert" className='alert alert-warning mt-1' style={{ fontSize: "12px" }}>
                    {
                        activeFierceTab === 'buy'
                            ?
                            `$ ${humanReadableNumber(amount) || 0} USD will be deducted from your Fierce Account and ${humanReadableNumber(redToken) || 0} RED will be added into your Solos Wallet`
                            :
                            `You will receive $ ${humanReadableNumber(amount) || 0} USD in your Fierce Account and ${humanReadableNumber(redToken) || 0} RED will be deducted from your Solos Wallet`
                    }
                </p>
            </div>
            {activeFierceTab === 'buy' ?
                <div className="transfer mt-2">
                    <label>Pay From</label>
                    <select
                        className="form-control custom-select"
                        value={'USD'}>
                        <option value={'USD'}> <img alt="" src={redTokenData?.image}></img> US Dollar Max {humanReadableNumber(availableBalance)}</option>
                        <option value={'EUR'}>EURO Max 0</option>
                    </select>
                </div>
                :
                <div className="d-flex transfer mt-2 justify-content-between">
                    <label>Available Balance :</label>
                    <input disabled className="w-auto" type='text' value={`${Number(
                        Number(redTokenData?.balance)
                            .toString()
                            .match(/^-?\d+(?:.\d{0,2})?/)?.[0]
                    ).toLocaleString()} ${redTokenData?.symbol}`} />
                </div>
            }
            {
                (redToken || amount) && disabled ? <p className="text-danger my-2" style={{ fontWeight: "500", paddingLeft: '26px' }}>
                    Insufficient balance!!
                </p> : null
            }
            <button onClick={handleClick} disabled={disabled} className={`rounded rounded-4 btn ${activeFierceTab === 'sell' ? `btn${disabled ? '-outlined' : ''}-primary` : `btn${disabled ? '-outlined' : ''}-success`} w-100 mt-3 p-3  text-capitalize`}>
                {
                    fierceLoader ?
                        <span className="typeWriter">
                            processing<span> . . . . .</span>
                        </span>
                        :
                        activeFierceTab
                }
            </button>
        </div >)
}

export default BuyTab

/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { FaRegEnvelope } from "react-icons/fa";
import logo from "../images/r-logo.svg";
import { verifyOtp } from "../services/userService";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { fireToast } from "../common/Toster";

const ForgotOtp = () => {
  let navigate = useNavigate();
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: localStorage.getItem("forgot-data"),
      otp: "",
    },
    validationSchema: Yup.object({
      otp: Yup.string()
        .min(4, "Invalid otp")
        .max(4, "Invalid otp")
        .required("This Field is Required"),
      // otp: Yup.string().required("This Field is Required"),
    }),
    onSubmit: async (values) => {
      try {
        // changeApiStatus(true, "");
        // console.log(values);
        const loginResponse = await verifyOtp(values);
        if (loginResponse.status === 200) {
          // console.log(loginResponse, "loginResponse");
          fireToast("success", loginResponse.message);
          localStorage.setItem("token-info", loginResponse.data.token);
          navigate("/forgot-password");
        } else {
          // changeApiStatus(false, "");

          throw new Error(loginResponse.error);
        }
      } catch (err) {
        fireToast("error", err?.response?.data?.error);
        // changeApiStatus(false, "");
      }
    },
  });
  return (
    <div className="auth-bg forgot-mail mh-100 d-flex align-items-center justify-content-center">
      <div className="auth-card py-lg-3">
        <div className="auth-inner">
          <div className="text-center mb-4 mb-lg-5">
            <Link to="/">
              <img className="mx-auto" src={logo} alt="" />
            </Link>
          </div>

          <h1 className="fs-37 mb-4">Forgot Password</h1>

          <Form
            // className="form-horizontal"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <Form.Group>
              <Form.Label className="form-label">OTP</Form.Label>
              <Form.Control
                name="otp"
                placeholder={"Enter otp"}
                type="text"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.otp || ""}
                invalid={
                  validation.touched.otp && validation.errors.otp
                    ? "true"
                    : "false"
                }
              />
              {validation.touched.otp && validation.errors.otp ? (
                // <Form.Control.Feedback type="invalid" className="text-danger">
                //   {validation.errors.otp}
                // </Form.Control.Feedback>
                <div className="text-danger">{validation.errors.otp}</div>
              ) : null}
            </Form.Group>

            <div className="mt-3 d-grid">
              <Button
                className="common-btn mt-4 mb-3 w-100"
                variant="primary"
                type="submit"
              >
                <strong>Submit</strong>
              </Button>
            </div>
          </Form>

          {/* <Form>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Verification Code</Form.Label>
              <div className="input-inner position-relative">
                <Form.Control type="text" placeholder="*******" />
                <span className="input-icon">
                  <FaRegEnvelope />
                </span>
              </div>
            </Form.Group>

            <Button
              className="common-btn mt-4 mb-3"
              variant="primary"
              type="submit"
            >
              Submit
            </Button>
           
          </Form> */}
        </div>
      </div>
    </div>
  );
};

export default ForgotOtp;

/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from "react";
import { getAllUserMarketplaceSFreds } from "../services/NftServices";
import { Link } from "react-router-dom";
import { FaCircleChevronLeft, FaCopy } from "react-icons/fa6";
import { BiChevronRight } from "react-icons/bi";
import SellNftCard from "../components/Nft/SellNftCard";
import { useNavigate } from "react-router-dom";
import SfredAbi from "../Abis/SfredAbi.json";
import Loader from "../components/loader/loader";
import { ethers } from "ethers";
import SellSFREDCard from "../components/SuperFred/SellSFREDCard";
import NoData from "../components/NoDataComp/NoData";
import Pagination from "@mui/material/Pagination";
import TvtAbi from "../Abis/TvtAbi.json";
import { ConversionContext } from "../Context/context";
import SelltvtCard from "../components/Tvt/SellTvtCard";
import { useActiveAccount } from "thirdweb/react";
import { ethers5Adapter } from "thirdweb/adapters/ethers5";
import { client } from "../runtime.config";
import { polygon } from "thirdweb/chains";
const debounceTime = 300;
let distinctUntilChanged = null;

const ViewAllSellSfreds = () => {
  const navigate = useNavigate();
  const activeAccount=useActiveAccount();
  const [loading, setLoading] = useState(false);
  const contextData = useContext(ConversionContext);
  const [filter, setFilter] = useState("");
  const [page, setPage] = useState(1);
  const UserId = contextData?.userId;
  const [userSuperFredData, setUserSuperFredData] = useState([]);

  const [totalData, setTotalData] = useState("");
  const fetchUserSuperFreds = async () => {
    try {
      setLoading(true);
      const response = await getAllUserMarketplaceSFreds();
      if (response.status === 200) {
        setLoading(false);
        const allSFredData = response.data.items;
        // console.log(allSFredData, "allSFredData");
        let result = [];
        for (let index = 0; index < allSFredData.length; index++) {
          const element = allSFredData[index];
          let res = await getMarketplaceSFreds(element?.contractAddress);

          // console.log(res, "response12121212");
          if (res) {
            if (res.length > 0) {
              for (let index = 0; index < res.length; index++) {
                const sfredDetail = res[index];
                // eslint-disable-next-line eqeqeq
                if (sfredDetail?.SfredCount != 0) {
                  result.push({
                    ...element,
                    balance: sfredDetail?.SfredPrice.toString(),
                    quantity: sfredDetail?.SfredCount.toString(),
                    address: sfredDetail?.userAddress,
                  });
                }
              }
            }
          }
        }
        // console.log(result, "response12121212");
        setUserSuperFredData(result);
      } else {
        throw new Error(response.error);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching super fred data:", error);
    }
  };
  const getMarketplaceSFreds = async (data) => {
    const signer = await ethers5Adapter.signer.toEthers({ client, chain:polygon, account:activeAccount });
    const sfredContract = new ethers.Contract(data, SfredAbi, signer);
    let respones = await sfredContract?.getAllUserData();
    // console.log(respones, "response12121212");
    return respones;
  };
  useEffect(() => {
    fetchUserSuperFreds();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="p-4">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Link
          className="back-btn fw-500 text-black py-4"
          onClick={() => navigate(-1)}
        >
          <i className="pe-2 align-middle">
            <FaCircleChevronLeft />
          </i>
          Back
        </Link>
        {/* <div>
          <input
            type="search"
            placeholder="Search NFT"
            style={{
              borderRadius: "12px",
              border: "none",
              padding: "5px 20px",
              background: "#d3d3d3a1",
            }}
            value={filter}
            onChange={(e) => handleSearch(e.target.value)}
          />
        </div> */}
      </div>
      {loading ? (
        <Loader />
      ) : (
        <div className="dashboard-centerd">
          {userSuperFredData?.length > 0 ? (
            <div className="row g-0">
              <div className="row mb-4">
                {userSuperFredData?.map((item, i) => {
                  return (
                    <div
                      key={i}
                      className="col-xxl-3 col-xl-3 col-md-6 col-12 mb-4"
                    >
                      <SellSFREDCard
                        UserId={UserId}
                        data={item}
                        // fetchData={fetchUserMarketplaceFredNfts}
                        setLoading={setLoading}
                      />
                    </div>
                  );
                })}
              </div>
              {/* {totalData > 12 && (
                <div
                  style={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    // minWidth: "200%",
                  }}
                >
                  <Pagination
                    color="primary"
                    count={Math.ceil(totalData / 12)}
                    page={page}
                    onChange={(e, v) => setPage(v)}
                    // activePage={page}
                  />
                </div>
              )} */}
            </div>
          ) : (
            <NoData />
          )}
        </div>
      )}
    </div>
  );
};

export default ViewAllSellSfreds;

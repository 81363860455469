/* eslint-disable no-unused-vars */
import React, { useContext, useState } from "react";
import arrowdown from "../../images/arrow-down.png";
import { ConversionContext } from "../../Context/context";
import configURl, { client } from "../../runtime.config";
import PinConfirm from "../Modals/PinConfirm";
import { getConversionRate, requestRedToken } from "../../services/propertyServices";
import { fireSuccessToast, fireToast } from "../../common/Toster";
import { ethers } from "ethers";
import usdcAbi from "../../Contracts/usdc.json";
import { toast } from "react-toastify";
import { useActiveAccount } from "thirdweb/react";
import { polygon } from "thirdweb/chains";
import { getContract, sendAndConfirmTransaction } from "thirdweb";
import { balanceOf, decimals, transfer } from "thirdweb/extensions/erc20";
import { ethers5Adapter } from "thirdweb/adapters/ethers5";

function ConvertTab() {
  const {
    userData,
    fetchAssets,
    redTokenData,
    usdcTokenData,
    conversionRate
  } = useContext(ConversionContext);
  const isPinActive = userData?.isMpinActive;
  const activeAccount = useActiveAccount();
  const [amount, setAmount] = useState("");
  const [loader, setLoader] = useState(false);
  const [redToken, setRedToken] = useState("");
  const [swapType, setSwapType] = useState("sell");

  const redContract = getContract({
    address: configURl.redNewAddress,
    chain: polygon,
    client,
  });

  const usdcContract = getContract({
    address: configURl.usdcAddress,
    chain: polygon,
    client,
  });

  const [showPinModal, setShowPinModal] = useState(false);
  const handleClosePinModal = () => {
    setShowPinModal(false);
  };

  async function buyFunction() {
    if (amount <= 0) {
      fireToast("error", "please enter amount...");
    } else {
      try {
        setLoader(true);
        const exChangeRate = await getConversionRate();
        const { receivewalletaddress } = exChangeRate.data;
        const amt = Number(amount).toFixed(5).toString();
        const amountToBePassed = ethers.utils.parseUnits(amt, 6);
        const signer = await ethers5Adapter.signer.toEthers({
          client,
          chain: polygon,
          account: activeAccount
        });
        const maticBalance = ethers.utils.formatEther(await signer.getBalance());
        // const maticBalance = activeBalance?.displayValue
        if (+maticBalance < configURl.minMaticLimit) {
          fireToast("error", "Insufficient matic funds for gas price !");
          setLoader(false);
          return;
        }

        const balance = ethers.utils.formatUnits(
          await balanceOf({
            contract: usdcContract,
            address: activeAccount?.address
          }),
          await decimals({
            contract: usdcContract
          })
        );
        if (+balance < +amt) {
          fireToast("error", "Transfer amount exceeds balance !");
          setLoader(false);
          return;
        }

        const transaction = transfer({
          contract: usdcContract,
          to: receivewalletaddress,
          amount: +amt
        });

        const response = await sendAndConfirmTransaction({ transaction, account: activeAccount });
        console.log(response);

        if (response.transactionHash) {
          const requestData = {
            accountType: "PRIMARY",
            transactionType: "Buy RED",
            usdc: amount,
            hashId: response.transactionHash,
            totalRedToken: (+redToken).toString(),
            walletAddress: activeAccount?.address,
          };
          await requestRedToken(requestData).then(
            (response) => {
              setLoader(false);
              console.log(response);
              setAmount("");
              setRedToken("");
              fetchAssets();
              fireSuccessToast("Successfully Done!");
            },
            (error) => {
              setLoader(false);
              console.log(error);
              toast.error("Transaction Failed!");
            }
          );
        }
      } catch (error) {
        setLoader(false);
        console.log(error);
      }
    }
  }

  async function sellFunction() {
    if (+redToken <= 0) {
      fireToast("error", "please enter amount...");
    } else {
      try {
        setLoader(true);
        const exChangeRate = await getConversionRate();
        const { receivewalletaddress } = exChangeRate.data;

        if (+redToken <= 0) {
          throw new Error("Amount must be greater than 0 !");
        }

        const balance = ethers.utils.formatUnits(
          await balanceOf({
            contract: redContract,
            address: activeAccount?.address
          }),
          await decimals({
            contract: redContract
          })
        );
        if (+balance < +redToken) {
          throw new Error("Transfer amount exceeds balance !");
        }
        const transaction = transfer({
          contract: redContract,
          to: receivewalletaddress,
          amount: +redToken
        });

        const response = await sendAndConfirmTransaction({
          transaction: transaction,
          account: activeAccount,
          ...(configURl?.isLive ? {
            gasless: {
              provider: 'engine',
              relayerUrl: configURl?.relayerUrl,
              relayerForwarderAddress: configURl?.relayerForwarderAddress
            }
          } : null)
        });
        if (response?.transactionHash) {
          const requestData = {
            accountType: "PRIMARY",
            transactionType: "Sell RED",
            usdc: amount,
            hashId: response.transactionHash,
            totalRedToken: (+redToken).toString(),
            walletAddress: activeAccount?.address,
          };
          await requestRedToken(requestData).then(
            (response) => {
              setLoader(false);
              setAmount("");
              setRedToken("");
              fetchAssets();
              console.log(response);
              fireSuccessToast("Successfully Done!");
            },
            (error) => {
              setLoader(false);
              console.log(error);
              toast.error("Transaction Failed!");
            }
          );
        }
      } catch (error) {
        setLoader(false);
        fireToast("error", error);
        console.log(error);
      }
    }
  }

  const handleSubmit = () => {
    if (isPinActive) {
      setShowPinModal(true);
    } else {
      performTransaction();
    }
  };

  const performTransaction = async () => {
    if (swapType === 'sell') {
      await sellFunction();
    } else {
      await buyFunction();
    }
  }

  const disabled = (swapType === 'sell' ? +redToken > +redTokenData?.balance : +amount > +usdcTokenData?.balance) || !(+redToken || +amount)

  return (
    <>
      <div className="swap">
        <div className="swap-sell">
          <div className="sell-col1">
            <label>Sell</label>
            {
              swapType === 'sell'
                ?
                <>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="0"
                    value={redToken}
                    onChange={(e) => {
                      setRedToken(e.target.value);
                      setAmount(+(e.target.value * conversionRate).toFixed(4));
                    }}
                  ></input>
                  <span className={(+redToken > +redTokenData?.balance) ? 'text-danger' : ''}>
                    max value :
                    <span className="ms-1">
                      {redTokenData
                        ? Number(
                          Number(
                            +redTokenData?.balance
                          )
                            .toString()
                            .match(/^-?\d+(?:.\d{0,2})?/)?.[0]
                        ).toLocaleString()
                        : 0}
                    </span>
                  </span>
                </>
                :
                <>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="0"
                    value={amount}
                    onChange={(e) => {
                      setRedToken(+(e.target.value / conversionRate).toFixed(4));
                      setAmount(e.target.value);
                    }}
                  ></input>
                  <span className={(+amount > +usdcTokenData?.balance) ? 'text-danger' : ''}>
                    max value :
                    <span className="ms-1">
                      {usdcTokenData
                        ? Number(
                          Number(
                            +usdcTokenData?.balance
                          )
                            .toString()
                            .match(/^-?\d+(?:.\d{0,2})?/)?.[0]
                        ).toLocaleString()
                        : 0}
                    </span>
                  </span>
                </>
            }
          </div>
          <div className="sell-col2">
            {
              swapType === 'sell' ?
                <button
                  type="button"
                  className="btn"
                >
                  <img alt="" src={redTokenData?.image} />
                  {redTokenData?.symbol}
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </button>
                :
                <button
                  type="button"
                  className="btn"
                >
                  <img alt="" src={usdcTokenData?.image} />
                  {usdcTokenData?.symbol}
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </button>
            }
          </div>
        </div>
        <div className="swap-sell">
          <div className="sell-col1">
            <label>Buy</label>
            {
              swapType === 'buy' ?
                <>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="0"
                    value={redToken}
                    onChange={(e) => {
                      setRedToken(e.target.value);
                      setAmount(+(e.target.value * conversionRate).toFixed(4));
                    }}
                  ></input>
                  <span className={(+redToken > (+usdcTokenData?.balance / +conversionRate)) ? 'text-danger' : ''}>
                    max value :
                    <span className="ms-1">
                      {usdcTokenData
                        ? Number(
                          Number(
                            +usdcTokenData?.balance / +conversionRate
                          )
                            .toString()
                            .match(/^-?\d+(?:.\d{0,2})?/)?.[0]
                        ).toLocaleString()
                        : 0}
                    </span>
                  </span>
                </>
                :
                <>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="0"
                    value={amount}
                    onChange={(e) => {
                      setRedToken(+(e.target.value / conversionRate).toFixed(4));
                      setAmount(e.target.value);
                    }}
                  ></input>
                  <span className={(+amount > (+redTokenData?.balance * +conversionRate)) ? 'text-danger' : ''}>
                    max value :
                    <span className="ms-1">
                      {redTokenData
                        ? Number(
                          Number(
                            +redTokenData?.balance * +conversionRate
                          )
                            .toString()
                            .match(/^-?\d+(?:.\d{0,2})?/)?.[0]
                        ).toLocaleString()
                        : 0}
                    </span>
                  </span>
                </>
            }
          </div>
          <div className="sell-col2">
            {
              swapType === 'buy' ?
                <button
                  type="button"
                  className="btn"
                >
                  <img alt="" src={redTokenData?.image} />
                  {redTokenData?.symbol}
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </button>
                :
                <button
                  type="button"
                  className="btn"
                >
                  <img alt="" src={usdcTokenData?.image} />
                  {usdcTokenData?.symbol}
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </button>
            }
          </div>
        </div>

        <button
          className="btn btn-light convert-btn"
          onClick={() => {
            setSwapType(prev => prev === 'buy' ? 'sell' : 'buy')
          }}
        >
          <img src={arrowdown} alt="Arrow Down" />
        </button>
      </div>
      {!loader ? (
        <button
          disabled={
            loader || disabled
          }
          className="btn w-100 mt-3 p-3 connect-wallet-btn"
          onClick={handleSubmit}
        >
          Convert
        </button>
      ) : (
        <button className="btn w-100 mt-3 p-3 connect-wallet-btn" type="button">
          <span className="typeWriter">
            processing<span> . . . . .</span>
          </span>
        </button>
      )}
      <PinConfirm
        setShow={() => { }}
        showPinModal={showPinModal}
        handleSubmit={performTransaction}
        handleClosePinModal={handleClosePinModal}
      />
    </>
  );
}

export default ConvertTab;

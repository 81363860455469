/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from "react";
import {
  getUserMarketplaceFreds,
  getAllUserMarketplaceFreds,
} from "../services/NftServices";
import FredAbi from "../Abis/FredAbi.json";
import { Link } from "react-router-dom";
import { BiChevronRight } from "react-icons/bi";
import { FaCircleChevronLeft, FaCopy } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import SellFredCard from "../components/Fred/SellFredCard";
import Loader from "../components/loader/loader";
import NoData from "../components/NoDataComp/NoData";
import { ConversionContext } from "../Context/context";
import { ethers } from "ethers";
import { useActiveAccount } from "thirdweb/react";
import { ethers5Adapter } from "thirdweb/adapters/ethers5";
import { client } from "../runtime.config";
import { polygon } from "thirdweb/chains";

import Pagination from "@mui/material/Pagination";
const debounceTime = 300;
let distinctUntilChanged = null;

const ViewAllSellFreds = () => {
  const navigate = useNavigate();
  const activeAccount=useActiveAccount();
  const [fredData, setFredData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalData, setTotalData] = useState("");
  const contextData = useContext(ConversionContext);
  const UserId = contextData?.userId;
  const [filter, setFilter] = useState("");
  const fetchUserMarketplaceFredNfts = async () => {
    try {
      setLoading(true);
      const response = await getAllUserMarketplaceFreds();
      if (response.status === 200) {
        setLoading(false);
        const allFredData = response.data.items;
        console.log(allFredData, "allFredData");
        let result = [];
        for (let index = 0; index < allFredData.length; index++) {
          const element = allFredData[index];
          let res = await getMarketplaceFreds(element?.contractAddress);

          // console.log(res, "result from get market place tvts");
          if (res) {
            if (res.length > 0) {
              for (let index = 0; index < res.length; index++) {
                const fredDetail = res[index];
                // eslint-disable-next-line eqeqeq
                if (fredDetail?.fredCount != 0) {
                  result.push({
                    ...element,
                    balance: fredDetail?.fredPrice.toString(),
                    quantity: fredDetail?.fredCount.toString(),
                    address: fredDetail?.userAddress,
                  });
                }
              }
            }
          }
        }
        setFredData(result);
      } else {
        throw new Error(response.error);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching nft data:", error);
    }
  };
  const getMarketplaceFreds = async (data) => {
    const signer = await ethers5Adapter.signer.toEthers({ client, chain:polygon, account:activeAccount });
    const fredContract = new ethers.Contract(data, FredAbi, signer);
    let respones = await fredContract?.getAllUserData();
    return respones;
  };
  // const handleSearch = (val) => {
  //   const value = val.trim();
  //   setFilter(value);
  //   if (distinctUntilChanged) {
  //     clearTimeout(distinctUntilChanged);
  //     distinctUntilChanged = null;
  //   }
  //   distinctUntilChanged = setTimeout(() => {
  //     setFilter(value);
  //     fetchFreds(1, 12, value);
  //     distinctUntilChanged = null;
  //   }, debounceTime);
  // };

  useEffect(() => {
    fetchUserMarketplaceFredNfts();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="p-4">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Link
          className="back-btn fw-500 text-black py-4"
          onClick={() => navigate(-1)}
        >
          <i className="pe-2 align-middle">
            <FaCircleChevronLeft />
          </i>
          Back
        </Link>
        {/* <div>
          <input
            type="search"
            placeholder="Search FRED"
            style={{
              borderRadius: "12px",
              border: "none",
              padding: "5px 20px",
              background: "#d3d3d3a1",
            }}
            value={filter}
            onChange={(e) => handleSearch(e.target.value)}
          />
        </div> */}
      </div>
      {loading ? (
        <Loader />
      ) : (
        <div className="dashboard-centerd">
          {fredData?.length > 0 ? (
            <div className="row g-0">
              <div className="row mb-4">
                {fredData?.map((item, i) => {
                  return (
                    <div
                      key={i}
                      className="col-xxl-3 col-xl-3 col-md-6 col-12 mb-4"
                    >
                      <SellFredCard
                        data={item}
                        UserId={UserId}
                        fetchData={fetchUserMarketplaceFredNfts}
                        setLoading={setLoading}
                      />
                    </div>
                  );
                })}
              </div>
              {/* {totalData > 12 && (
                <div
                  style={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    // minWidth: "200%",
                  }}
                >
                  <Pagination
                    color="primary"
                    count={Math.ceil(totalData / 12)}
                    page={page}
                    onChange={(e, v) => setPage(v)}
                    // activePage={page}
                  />
                </div>
              )} */}
            </div>
          ) : (
            <NoData />
          )}
        </div>
      )}
    </div>
  );
};

export default ViewAllSellFreds;

import React from "react";
import "./SolosReward.css";
import Solos from "../../images/newcardlogo.svg";
const SolosReward = ({ SRTokenData, getTokenBalance }) => {
  return (
    <>
      <div
        className="solos-card d-flex flex-column justify-content-between position-relative"
        style={{ cursor: "pointer" }}
      >
        <div className="d-flex align-items-center justify-content-center">
          <img src={Solos} alt="" height={30} />
        </div>
        <div>
          <h6>
            <span className="text-white fw-400 scard-title">Solos Reward : </span>
            <span className="text-white text-start ps-1 scard-value">
              {+SRTokenData?.balance
                ? Number(
                  Number(SRTokenData?.balance)
                    .toString()
                    .match(/^-?\d+(?:.\d{0,2})?/)[0]
                ).toLocaleString()
                : 0}
            </span>
          </h6>
          <h6>
            <span className="text-white fw-400 scard-title">Value in USD : </span>
            <span className="text-white text-start ps-1 scard-value">
              $
              {SRTokenData
                ? Number(
                  Number(+SRTokenData?.balance * +SRTokenData?.conversionrate)
                    .toString()
                    .match(/^-?\d+(?:.\d{0,2})?/)[0]
                ).toLocaleString()
                : 0}
            </span>
          </h6>
        </div>

        {/* <div className="token-head d-flex align-items-center justify-content-between">
          <div>
            <span className="text-white fw-400 scard-title">Solos Reward</span>
            <h6 className="fs-24 text-white ps-1">
              {+SRTokenData?.balance
                ? Number(
                  Number(SRTokenData?.balance)
                    .toString()
                    .match(/^-?\d+(?:.\d{0,2})?/)[0]
                ).toLocaleString()
                : 0}
            </h6>
            <span className="text-white fw-400 scard-sub-title">
              Value in USD
            </span>
            <h6 className="fs-24 text-white ps-1">
              $
              {SRTokenData
                ? Number(
                  Number(+SRTokenData?.balance * +SRTokenData?.conversionrate)
                    .toString()
                    .match(/^-?\d+(?:.\d{0,2})?/)[0]
                ).toLocaleString()
                : 0}
            </h6>
          </div>
        </div> */}
        <div className="token-bottom">
          {/* <div
            className="row d-flex align-items-center text-center"
          >
            <span className="col-3 d-flex justify-content-center text-center fs-8 mb-0 text-white fw-500">
              {" "}
              <SendSr getBalance={getTokenBalance} balance={+SRTokenData?.balance} />
            </span>
            <span className="col-3 d-flex justify-content-center text-center fs-8 mb-0 text-white fw-500">
              <Receive title={'SR'} />
            </span>
            <span className="col-3 d-flex justify-content-center text-center fs-8 mb-0 text-white fw-500 cursor-pointer">
              <BuySr getBalance={getTokenBalance} balance={+SRTokenData?.balance} />
            </span>
            <span className="col-3 d-flex justify-content-center text-center fs-8 mb-0 text-white fw-500 cursor-pointer">
              <SellSr getBalance={getTokenBalance} balance={+SRTokenData?.balance} />
            </span>
          </div> */}
        </div>
        {/* <div
          className="text-center"
          style={{ position: "absolute", top: "20px", right: "15px" }}
        >
          <img src={Solos} alt="" height={22} />
        </div> */}
      </div>
    </>
  );
};

export default SolosReward;
